/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { useState } from "react";
import "../../styles/myprofile.css";
import AboutModal from "./aboutModal";
import ProfileResume from "./resume";
import DesktopHeader from "../desktopHeader";
import EducationModal from "./educationModal";
import ExperienceModal from "./experienceModal";
import ProjectModal from "./projectModal";
import CourseModal from "./courseModal";
import PlayIcon from "../../images/PlayIcon.svg";
import Resume from "../../images/Document Text.svg";
import SocialLink from "../../images/Link.svg";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Delete from "../../images/icons/delete-btn.svg";
import Download from "../../images/Download Minimalistic.svg";
import Edit from "../../images/icons/edit-btn-2.svg";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import gradCap from "../../images/icons/graduation-cap.svg";
import optionDots from "../../images/icons/option-dots-btn.svg";
import Note from "../../images/Notebook Minimalistic.png";
import currentResume from "../../images/landing/currentResume.svg";
import uploadResumeSvg from "../../images/landing/uploadResumeSvg.svg";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Common from "../../utils/common";
import ContactWebsite from "../../images/Letter Opened.svg";
import Contactmail from "../../images/Global.svg";
import Contactjoin from "../../images/Global.png";
import ContactNo from "../../images/Smartphone 2.png";
import Email from "../../images/icons/envelope-open.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Star from "../../images/StarIcon.svg";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Education from "../../images/image 134.svg";
import EducationOne from "../../images/Education.png";
import imageAvatars from "../../images/EducationDefault.svg";
import ClickOrUpload from "../camera-or-upload/clickOrUploadPicture";
import CloseIcon from "../../images/CloseIcon.svg";
import gradBg from "../../images/gradient-bg.png";
import star from "../../images/icons/star.svg";
import experience_logo from "../../images/xakal-exp.svg";
import { Country } from "country-state-city";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import imageAvatar from "../../images/company.png";
import styled from "styled-components";
import { Amplify, Auth } from "aws-amplify";
import Service from "./service";
import ChangePassword from "../../images/Password.png";
import Loggedout from "../../images/Logout 3.png";
import Update from "../../images/Update.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Slider from "react-slick";
import Dropdown from "react-bootstrap/Dropdown";
import OtherService from "./otherservice";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { CiLinkedin } from "react-icons/ci";
import MobileHeader from "../mobileHeader";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Followings from "../../images/Followings.png";
import Message from "../../images/Message.png";
import Conversation from "../conversation/conversation";
import MessageChat from "../conversation/message";
import ProfileSkills from "./profileSkills";
import { RiMessage2Line } from "react-icons/ri";
import "../conversation/mes.css";
import "../conversation/message.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { LuSendHorizonal } from "react-icons/lu";
import { IoIosAttach } from "react-icons/io";
import { io } from "socket.io-client";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { GoSmiley } from "react-icons/go";
import ChatMain from "../conversation/chatMain";
import ResumeSkill from "./resumeSkill";
import jszip from "jszip";
import { Spinner } from "react-bootstrap";
import Certificate from "../Certificate/certificate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ManageInterview from "../manage-interview/manageInterview";
import SocialMediaPost from "../SocialMedia/socialMediaPost";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Wrapper = styled.div``;
const Wrappers = styled.div`
  margin-left: 0px;
  padding-bottom: 30px;
  // @media (max-width: 1024px) {
  //   margin-left: 210px;
  // }
`;
const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-right: 10px;
  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;
const HRLine = styled.div`
  stroke-width: 1px;
  border: 1px solid rgba(168, 161, 172, 0.2);
  margin: 0px 10px;
  stroke: rgba(168, 161, 172, 0.2);
  height: 50px;
  @media (max-width: 576px) {
    display: none;
  }
`;
const Button = styled.div`
  background-color: #8653b6;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  max-width: 200px;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const JobTitle = styled.div`
  // width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    // margin: -15px 0 0 0;
  }
`;
const ChangePass = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;
const Logout = styled.div`
  color: #ff5252;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;
const Span = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  margin-top: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
`;
const NativeLink = styled.div``;
const PopDiv = styled.div`
  position: relative;
  // display: flex;
  // justify-content: space-around;
  margin-top: 25px;
  padding-left: 20px;
`;
const TextDiv = styled.div`
  font-family: Inter-Medium, sans-serif;
  color: #362a41;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 20px;
`;

const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  // position:relative;
  top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position:relative;
  top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const MyProfile = ({ fetchUserDetails }) => {
  const { id } = useParams();
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    // skill: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    phoneNumber: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
  });

  const [title, setTitle] = useState(null);
  const [aboutSkill, setAboutSkill] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [primaryAddress, setPrimaryAddress] = useState();
  const [about, setAbout] = useState(false);
  const [profileSkills, setProfileSkills] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);
  const [education, setEducation] = useState(false);
  const [experience, setExperience] = useState(false);
  const [editeducation, setEditeducation] = useState(null);
  const [editexperience, setEditexperience] = useState(null);
  const [editproject, setEditproject] = useState(null);
  const [editcourse, setEditcourse] = useState(null);

  const [project, setProject] = useState(false);
  const [course, setCourse] = useState(false);
  const [educationDetail, setEducationdetails] = useState([]);
  const [experienceDetail, setExperiencedetails] = useState([]);
  const [projectDetail, setProjectdetails] = useState([]);
  const [courseDetail, setCoursedetails] = useState([]);
  const [imageUploadPopup, setImageUploadPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState({});
  const [showcased, setShowcased] = useState(true);

  const [profileImage, setProfileImage] = useState(null);

  const [hostRecordings, setHostRecordings] = useState([]);
  const [guestRecordings, setGuestRecordings] = useState([]);
  const [archievedrecordings, setArchievedRecordings] = useState([]);
  const location = useLocation();
  const [userRole, setUserRole] = useState(false);
  const [candidateRole, setCandidateRole] = useState();
  const [userRoleHr, setUserRoleHr] = useState(false);
  const [uploadResumePopup, setUploadResumePopup] = useState(false);
  const [resumeIds, setIdResumes] = useState();
  const [resumesIds, setIdsResumes] = useState();

  //resume new flow
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [previewPopup, setPreviewPopup] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  ////
  const [selectedImage, setSelectedImage] = useState(null);

  const [showcontentexp, setShowContentExp] = useState([]);
  const [showcontentpro, setShowContentPro] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [contactDetails, setContactDetails] = useState(false);
  const [user, setUsers] = useState("");
  const [shrink, setShrink] = useState(true);
  const [enableService, setEnableService] = useState(false);
  const [enableRepository, setEnableRepository] = useState(false);
  const [enableServices, setEnablesService] = useState();
  const [open, setOpen] = useState();
  const [inactives, setInactives] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [userId, setUserId] = useState("");
  const [otherProfile, setOtherProfile] = useState(false);
  const [conversation, setConversation] = useState([]);

  const [blockedUsers, setBlockedUsers] = useState([]);
  const [blockesdStatus, setBlockedStatus] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [messageChats, setMessageChats] = useState(null);
  const [messageChat, setMessageChat] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [openConver, setOpenConver] = useState(false);
  const [detail, setDetail] = useState();
  //handlePost
  const [postEnable, setPostEnable] = useState(false);
  const handleUploadResume = () => {
    setUploadResumePopup(true);
  };
  const handleShowcase = () => {
    setShowcased(true);
  };

  const handleArchive = () => {
    setShowcased(false);
  };
  const getRecordingDataHost = async () => {
    const userId = Common.loggedIn(id);
    try {
      const res = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/repositories/host/recording/${Common.loggedIn(id)}`
      );
      // .then((res) => {
      const unArchieved = res?.data?.filter(
        (item) =>
          !item?.recording?.archievedBy?.includes(userId) &&
          !item?.recording?.deletedBy?.includes(userId)
      );
      const archieved = res?.data?.filter(
        (item) =>
          item?.recording?.archievedBy?.includes(userId) &&
          !item?.recording?.deletedBy?.includes(userId)
      );
      setHostRecordings(unArchieved);
      setArchievedRecordings(archieved);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  const getRecordingDataGuest = async () => {
    const userId = Common.loggedIn(id);
    try {
      const res = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/repositories/guest/recording/${Common.loggedIn(id)}`
      );
      const unArchieved = res?.data?.filter(
        (item) =>
          !item?.recording?.archievedBy?.includes(userId) &&
          !item?.recording?.deletedBy?.includes(userId)
      );
      const archieved = res?.data?.filter(
        (item) =>
          item?.recording?.archievedBy?.includes(userId) &&
          !item?.recording?.deletedBy?.includes(userId)
      );
      setGuestRecordings(unArchieved);
      setArchievedRecordings(archieved);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  useEffect(() => {
    setArchievedRecordings([]);
    getRecordingDataHost();
    getRecordingDataGuest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const archieveRecording = async (sessionId) => {
    try {
      await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/repositories/archieve/${Common.loggedIn(id)}/${sessionId}`
      );
      setHostRecordings([]);
      setGuestRecordings([]);
      setArchievedRecordings([]);
      getRecordingDataHost();
      getRecordingDataGuest();
    } catch (error) {
      console.log("Error fetching archive details:", error);
    }
  };

  const unArchieveRecording = async (sessionId) => {
    try {
      await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/repositories/unarchieve/${Common.loggedIn(id)}/${sessionId}`
      );
      setHostRecordings([]);
      setGuestRecordings([]);
      setArchievedRecordings([]);
      getRecordingDataHost();
      getRecordingDataGuest();
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const deleteRecording = async (sessionId) => {
    try {
      await axios.get(
        `${process.env.PUBLIC_URL}/xakal/repositories/delete/${Common.loggedIn(
          id
        )}/${sessionId}`
      );
      setHostRecordings([]);
      setGuestRecordings([]);
      setArchievedRecordings([]);
      getRecordingDataHost();
      getRecordingDataGuest();
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const handleClick = () => {
    setAbout(true);
  };
  const handleSkills = () => {
    setProfileSkills(true);
  };
  const handleResume = () => {
    setResumeModal(true);
  };
  function handleEducation(id = null) {
    setEditeducation(id);
    setEducation(true);
  }
  function handleEducationMob() {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/education",
        state: { eduMobile: true },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleEducation(null); // Your existing popup handling function
    }
  }
  function handleEditEducation(id) {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/education",
        state: { eduMobile: true, eduIds: id },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleEducation(id); // Your existing popup handling function
    }
  }
  function handleExperience(id = null) {
    setExperience(true);
    setEditexperience(id);
  }
  function handleExperienceMob() {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/experience",
        state: { expMobile: true },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleExperience(null); // Your existing popup handling function
    }
  }
  function handleEditExperience(id) {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/experience",
        state: { expMobile: true, expIds: id },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleExperience(id); // Your existing popup handling function
    }
  }
  function handleProject(id = null) {
    setProject(true);
    setEditproject(id);
  }
  function handleProjectMob() {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/project",
        state: { proMobile: true },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleProject(null); // Your existing popup handling function
    }
  }
  function handleEditProject(id) {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/project",
        state: { proMobile: true, proIds: id },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleProject(id); // Your existing popup handling function
    }
  }
  function handleCourse(id = null) {
    setCourse(true);
    setEditcourse(id);
  }
  function handleCourseMob(id) {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/course",
        state: { courMobile: true },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleCourse(null); // Your existing popup handling function
    }
  }
  function handleEditCourse(id) {
    const isMobile = window.innerWidth < 768; // You can adjust the breakpoint
    if (isMobile) {
      // Navigate to a new page for mobile view
      history.push({
        pathname: "/portal/course",
        state: { courMobile: true, courIds: id },
      }); // Replace with your actual mobile page URL
    } else {
      // Open popup for desktop view
      handleCourse(id); // Your existing popup handling function
    }
  }
  const handleDownload = (id, price) => {
    window.open(id, "_blank");
    // if (otherProfile) {
    //   axios
    //     .post(
    //       `${
    //         process.env.PUBLIC_URL
    //       }/xakal/about/getpayment/${Common.loggedIn()}`,
    //       { link: id }
    //     )
    //     .then((response) => {
    //       if (
    //         response.data &&
    //         response.data.payment &&
    //         response.data.payment.paid &&
    //         response.data.payment.paid === "paid"
    //       ) {
    //         window.open(id, "_blank");
    //       } else {
    //         checkForCredit(id, price);
    //       }
    //     });
    // } else {

    // }
  };

  // eslint-disable-next-line no-unused-vars
  const checkForCredit = (id, price) => {
    if (companyData && companyData.availableProfileCredit > 0) {
      const payload = {
        availableProfileCredit: --companyData.availableProfileCredit,
        totalProfileUsed: ++companyData.totalProfileUsed,
        totalCredit: ++companyData.totalCredit,
      };
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/update-company/update/${companyData._id}`,
          payload
        )
        .then((companyResponse) => {
          if (companyResponse.data.success) {
            axios
              .post(
                `${
                  process.env.PUBLIC_URL
                }/xakal/about/payment/${Common.loggedIn()}`,
                { link: id }
              )
              .then((paymentResponse) => {
                if (paymentResponse.data.success) {
                  window.open(id, "_blank");
                }
              })
              .catch((err) => err.error);
          }
        })
        .catch((err) => err.error);
    } else {
      proceedToPay(id, price);
    }
  };

  const proceedToPay = async (id, price) => {
    const amount = Math.round(parseFloat(price) * 100);
    const currency = "INR";

    const payload = {
      amount,
      currency,
      receipt: "qwerr1",
    };

    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/payment`,
        payload
      );
      const data = response.data.order.id;

      const options = {
        key: "rzp_test_COGUtktXGWRh99", // You can uncomment the next line if needed
        // key: `${process.env.RAZORPAY_KEY_ID_FRONT}`,
        amount,
        currency,
        name: "Xakal ",
        description: "Transaction",
        image: "https://imagesjob.s3.ap-south-1.amazonaws.com/logopng.png",
        order_id: data,
        handler: async function (response) {
          const body = {
            ...response,
          };

          try {
            const validateResponse = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/payment/validate`,
              body
            );
            try {
              const updateResponse = await axios.post(
                `${
                  process.env.PUBLIC_URL
                }/xakal/about/payment/${Common.loggedIn()}`,
                { link: id }
              );
              // Show toaster message
              toast.success("Payment is successfully completed");
              // Redirect to the user's profile
              window.open(id, "_blank");
            } catch (updateErr) {
              toast.error("Payment Failed");
            }
          } catch (validateErr) {
            console.error("Validation error:", validateErr);
          }
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
      });
      rzp1.open();
    } catch (err) {
      console.error("Payment initiation error:", err);
      // Optional: display an error message to the user
      toast.error("Failed to initiate payment, please try again.");
    }
  };

  useEffect(() => {
    document.title = `${input?.firstName} ${input?.lastName} | Xakal`;
    setTitle(`${input?.firstName} ${input?.lastName}` || "Others Profile");
  }, [input]);

  const mergeSkill = [...aboutSkill, ...softSkills];

  const prefillValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn(
          id
        )}`
      );
      if (response && response.data && response.data.success) {
        setInput({
          ...input,
          userId: response.data.aboutOne.userId,
          id: response.data.aboutOne._id,
          firstName: response.data.aboutOne.firstName,
          lastName: response.data.aboutOne.lastName,
          middleName: response.data.aboutOne.middleName,
          email: response.data.aboutOne.email,
          bio: response.data.aboutOne.bio,
          city: response.data.aboutOne.city,
          state: response.data.aboutOne.state,
          avatar: response.data.aboutOne.avatar,
          country: response.data.aboutOne.country,
          language: response.data.aboutOne.language,
          proficiency: response.data.aboutOne.proficiency,
          about: response.data.aboutOne.about,
          gender: response.data.aboutOne.gender,
          workProfile: response.data.aboutOne.workProfile,
          urlLink: response.data.aboutOne.urlLink,
          phoneNumber: response.data.aboutOne.phoneNumber,
          softSkill: response.data.aboutOne.softSkill,
          inputList: response.data.aboutOne.inputList,
          inputList2: response.data.aboutOne.inputList2,
          inputList3: response.data.aboutOne.inputList3,
          createdDate: response.data.aboutOne.createdDate,
        });
        setUserRole(response.data.aboutOne.userType.includes("interviewer"));
        setCandidateRole(response.data.aboutOne.userType.includes("candidate"));
        setUserRoleHr(response.data.aboutOne.userType.includes("hr"));

        if (
          response.data.aboutOne.coreSkill &&
          response.data.aboutOne.coreSkill?.length > 0
        ) {
          setAboutSkill(response.data.aboutOne.coreSkill);
        }
        if (
          response.data.aboutOne.softSkill &&
          response.data.aboutOne.softSkill?.length > 0
        ) {
          setSoftSkills(response.data.aboutOne.softSkill);
        }
        setProfileImage(null);
        setPrimaryAddress(response.data.aboutOne.primaryAddress);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const deleteAvatar = async () => {
    try {
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-avatar/${input.userId}`
      );
      toast.success(response.data.message);
      setAbout(false);
      setDeleteOpen(false);
      prefillValue();
      setProfileImage(null);
      if (input) {
        input.avatar = ""; // or input.avatar = null;
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const deledu = async (aboutInput) => {
    const params = {
      online: aboutInput.online,
      university: aboutInput.university,
      degree: aboutInput.degree,
      specialization: aboutInput.specialization,
      startDate: aboutInput.startDate,
      endDate: aboutInput.endDate,
      percentage: aboutInput.percentage,
      educationType: aboutInput.educationType,
      skill: aboutInput.skill,
      deleteFlag: "Y",
      userId: Common.loggedIn(id),
    };

    try {
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-education/${aboutInput._id}`,
        params
      );
      if (response.data.success) {
        getalledudetails();
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const [educationSkill, setEducationSkill] = useState([]);
  const [expererienceSkill, setExperienceSkill] = useState([]);
  const [projectSkill, setProjectSkill] = useState([]);
  const [courseSkill, setCourseSkill] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);

  const getalledudetails = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-education-detail-all/${Common.loggedIn(id)}`
      );
      if (response && response.data && response.data.success) {
        setEducationdetails(response.data?.educationOne);
      }
      const skills = response.data.educationOne
        .map((cert) => cert.skill)
        .flat();
      setEducationSkill(skills);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const deleExp = async (experience) => {
    const params = {
      skill: experience.skill,
      company: experience.company,
      designation: experience.designation,
      industry: experience.industry,
      description: experience.description,
      startDate: experience.startDate,
      endDate: experience.endDate,
      employeeType: experience.employeeType,
      location: experience.location,
      workMode: experience.workMode,
      deleteFlag: "Y",
      userId: Common.loggedIn(id),
    };
    try {
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-expereince/${experience._id}`,
        params
      );
      if (response.data.success) {
        getallexpdetails();
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getallexpdetails = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-experience-detail-all/${Common.loggedIn(id)}`
      );
      if (response && response.data && response.data.success) {
        setExperiencedetails(response.data?.experience);
      }
      const skills = response.data.experience.map((cert) => cert.skill).flat();
      setExperienceSkill(skills);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const delePro = async (project) => {
    const params = {
      description: project.description,
      projectTitle: project.projectTitle,
      startDate: project.startDate,
      endDate: project.endDate,
      tool: project.tool,
      skill: project.skill,
      deleteFlag: "Y",
      userId: Common.loggedIn(id),
    };
    try {
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-project/${project._id}`,
        params
      );
      if (response.data.success) {
        getallprodetails();
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getallprodetails = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-project-detail-all/${Common.loggedIn(id)}`
      );
      if (response && response.data && response.data.success) {
        setProjectdetails(response.data?.projectOne);
      }
      const skills = response.data?.projectOne
        ?.map((cert) => cert.skill)
        .flat();
      setProjectSkill(skills);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const deleCourse = async (course) => {
    const params = {
      online: course.online,
      course: course.course,
      organisation: course.organisation,
      startDate: course.startDate,
      endDate: course.endDate,
      issueDate: course.startDate,
      validity: course.validity,
      skill: course.skill,
      deleteFlag: "Y",
      userId: Common.loggedIn(id),
    };
    try {
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-course/${course._id}`,
        params
      );
      if (response.data.success) {
        getallcourseDetails();
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getallcourseDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-certi-detail-all/${Common.loggedIn(
          id
        )}`
      );
      if (response && response.data && response.data.success) {
        setCoursedetails(response.data?.certiOne);
      }
      const skills = response.data.certiOne?.map((cert) => cert.skill).flat();
      setCourseSkill(skills);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getCompanyDataOnUser = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-company-by-user/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.success) {
        setCompanyData(response.data.company[0]);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const uploadHandle = () => {
    // setupload(true);
    setAbout(false);
    setImageUploadPopup(true);
  };
  const openEdit = () => {
    setEditOpen(true);
  };
  const uploadFile = () => {
    setAbout(false);
    setEditOpen(false);
    setImageUploadPopup(true);
  };

  // eslint-disable-next-line no-unused-vars

  function ToggleContentExp(i) {
    const newShowContentExp = [...showcontentexp];
    newShowContentExp[i] = !newShowContentExp[i];
    setShowContentExp(newShowContentExp);
  }

  function ToggleContentPro(i) {
    const newShowContentPro = [...showcontentpro];
    newShowContentPro[i] = !newShowContentPro[i];
    setShowContentPro(newShowContentPro);
  }

  const closeImagePopup = () => {
    setImageUploadPopup(false);
    setAbout(true);
    setInput({ ...input });
  };
  const imageUploadedByPopup = (url) => {
    setProfileImage(url);
    setAbout(true);
  };

  const deleteOpenFunction = () => {
    setEditOpen(false);
    setDeleteOpen(true);
  };

  const onSubmit2 = () => {
    setSuccess(true);
  };
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  const handleSection = () => {
    setShrink(true);
    setEnableService(false);
    setEnableRepository(false);
    setPostEnable(false);
    prefillValue();
    getallexpdetails();
    getalledudetails();
  };

  useEffect(() => {
    if (location?.state?.goBack) {
      handleRepository();
    } else if (location?.state?.goInActive) {
      handleService();
    }
  }, [location]);

  useEffect(() => {
    prefillValue();
    getallexpdetails();
    getallprodetails();
    getalledudetails();
    getallcourseDetails();
    getCompanyDataOnUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleService = () => {
    setEnableService(true);
    setShrink(true);
    setEnableRepository(false);
    setPostEnable(false);
  };
  const handleServices = () => {
    setEnablesService(true);
    setShrink(false);
    setEnableRepository(false);
    setPostEnable(false);
  };
  const handleRepository = () => {
    setEnableService(false);
    setShrink(false);
    setEnableRepository(true);
    setPostEnable(false);
  };
  const handlePost = () => {
    setEnableService(false);
    setShrink(false);
    setEnableRepository(false);
    setPostEnable(true);
  };
  const allSkills = [
    ...aboutSkill,
    ...courseSkill,
    ...expererienceSkill,
    ...projectSkill,
    ...educationSkill,
  ];

  const skillCounts = allSkills.reduce((counts, skill) => {
    const { label, value, rating, userId, skillIds } = skill;
    const key = `${label}_${value}_${rating}_${userId}_${skillIds}`;
    counts[key] = (counts[key] || 0) + 1;
    return counts;
  }, {});

  // Sort the array based on the count of occurrences
  const sortedSkills = allSkills.sort((a, b) => {
    const countA =
      skillCounts[
        `${a.label}_${a.value}_${a.rating}_${a.userId}_${a.skillIds}`
      ];
    const countB =
      skillCounts[
        `${b.label}_${b.value}_${b.rating}_${b.userId}_${a.skillIds}`
      ];
    return countB - countA; // Sort in descending order of counts
  });

  // Filter out items with identical label, value, rating, and userId, keeping the highest count
  const uniqueSkills = [];
  const addedSkills = new Set();

  sortedSkills.forEach((skill) => {
    const key = `${skill.label}_${skill.value}_${skill.rating}_${skill.userId}_${skill.skillIds}`;
    if (!addedSkills.has(key)) {
      uniqueSkills.push(skill);
      addedSkills.add(key);
    }
  });
  const separateRating = uniqueSkills.map((rate) => rate.averageSkillRating);
  const softRating = softSkills.map((rate) => rate.averageSkillRating);

  const combinedRating = separateRating.concat(softRating);

  const nonZeroRatings = combinedRating.filter(
    (rating) => typeof rating === "number" && !isNaN(rating) && rating !== 0
  );

  const sum = nonZeroRatings.reduce((acc, rating) => acc + rating, 0);
  //emlimate zero for consolidated rating
  const totalAverage =
    nonZeroRatings?.length > 0 ? sum / nonZeroRatings?.length : 0;

  // eslint-disable-next-line no-unused-vars

  console.log(totalAverage);
  const getUser = async () => {
    if (id) {
      setUserId(id);
      setOtherProfile(true);
    } else {
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/getuser`
        );
        setUserId(response.data?.userId);
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    }
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  };
  const handleProfileClick = () => {
    const id = input.userId; // Replace 'your-id' with the actual ID you want to pass
    const url = `https://test-staging.thexakal.com/portal/other-profile/${id}`;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setIsCopied(true);

        // Reset the copy status after a certain duration (e.g., 3 seconds)
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        // Unable to copy URL to clipboard
        console.error("Failed to copy URL: ", err);
      });
  };
  useEffect(() => {
    document.title = "My Profile | Xakal";
  }, []);
  const searchedKey = () => {};
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   document.body.style.overflow = "auto";
  // }, []);
  const { state } = useLocation();
  const [jobApply, setJobApply] = useState();
  const [other, setOtherProfiles] = useState();
  const [value, setValue] = useState("showcased");
  const [social, setOpenSocial] = useState(false);
  const [onPopupData, setPopupData] = useState();
  const [isCopieds, setIsCopieds] = useState(false);

  useEffect(() => {
    setJobApply(state && state.form);
    setOtherProfiles(state && state.goBack);
  }, [state]);
  const backPage = () => {
    if (jobApply) {
      history.push({
        pathname: `/portal/application-tracking-system`,
        state: { selectedCompany: jobApply },
      });
    } else if (other) {
      history.push({
        pathname: `/portal/interview/exploreInterview`,
      });
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    const payload = {
      totalAverageRating: totalAverage,
    };
    const fetchData = async () => {
      try {
        await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
          payload
        );
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    };
    fetchData();
  }, [totalAverage]);

  const handleWhatsAppShare = () => {
    const jobName = onPopupData.eventTitle;
    const jobId = onPopupData.recording.sessionId;
    const value = onPopupData.recording.recordingUrl;
    const bookingId = onPopupData.bookingId;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const queryParams = new URLSearchParams({
      value: value,
      bookingId: bookingId,
    });
    const jobUrl = `${domain}/portal/MyProfile/repoDetails/${jobId}?${queryParams.toString()}`;
    const message = `Check out my ${jobName} featuring along with recording and feedback:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData.recording.sessionId;
    const jobTitle = onPopupData.eventTitle;
    const value = onPopupData.recording.recordingUrl;
    const bookingId = onPopupData.bookingId;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const queryParams = new URLSearchParams({
      value: value,
      bookingId: bookingId,
    });
    const jobUrl = `${domain}/portal/MyProfile/repoDetails/${jobId}?${queryParams.toString()}`;

    const subject = `Share Repository: ${jobTitle} (Job ID: ${jobId})`;

    const body = `Check out my ${jobTitle} featuring along with recording and feedback:${jobUrl}`;
    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.eventTitle;
    const jobId = onPopupData.recording.sessionId;
    const value = onPopupData.recording.recordingUrl;
    const bookingId = onPopupData.bookingId;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const queryParams = new URLSearchParams({
      value: value,
      bookingId: bookingId,
    });
    const jobUrl = `${domain}/portal/MyProfile/repoDetails/${jobId}?${queryParams.toString()}`;

    const message = `Check out my ${jobTitle} featuring along with recording and feedback:`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const handleCopyToClipboard = () => {
    const jobId = onPopupData.recording.sessionId;
    const value = onPopupData.recording.recordingUrl;
    const bookingId = onPopupData.bookingId;
    const jobTitle = onPopupData.eventTitle;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/portal/MyProfile/repoDetails/${jobId}`;
    const queryParams = new URLSearchParams({
      value: value,
      bookingId: bookingId,
    });
    const message = `Check out my ${jobTitle} featuring along with recording and feedback:${jobUrl}?${queryParams.toString()}`;

    navigator.clipboard.writeText(message);

    navigator.clipboard.writeText(message).then(() => {
      setIsCopieds(true);

      setTimeout(() => {
        setIsCopieds(false);
      }, 3000);
    });
  };
  const openPopup = (data) => {
    setPopupData(data);
    setOpenSocial(true);
  };
  const getAllPending = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-pending-feedback`
      );
      setPendingFeedback(res.data.book);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  const [skills, setSkills] = useState(false);
  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const [associateCount, setAssociateCount] = useState(0);
  const [otherFollowers, setOtherFollowers] = useState([]);
  const [otherFollowing, setOtherFollowing] = useState([]);
  const [associateCountOther, setAssociateCountOther] = useState(0);
  const [otherFollowersOther, setOtherFollowersOther] = useState([]);
  const [otherFollowingOther, setOtherFollowingOther] = useState([]);
  const [myFollowers, setMyFollowers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [myFollowing, setMyFollowing] = useState([]);

  const [followingStatus, setFollowingStatus] = useState(false);

  // status
  const [openMore, setOpenMore] = useState(false);
  const [openBlockPopup, setOpenBlockPopup] = useState(false);
  const [requests, setRequests] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isAssociate, setIsAssociate] = useState(false);
  const [asscStatus, setAsscStatus] = useState(null);

  const [isFollowerForMe, setisFollowerForMe] = useState(false);
  const skillsLength = mergeSkill?.length;
  const handleOpen = () => {
    setSkills(!skills);
  };
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData?.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);
  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };

  const getMyConnectionDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`
      );
      const associatesdataCount = response?.data?.filter(
        (e) => e.connectionStatus === "ASSOCIATE"
      );
      setAssociateCount(associatesdataCount?.length);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getMyConnectionDetails();
    if (otherProfile) {
      getMyConnectionDetailss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOtherFollowers = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/followers/${Common.loggedIn()}`
      );
      if (res?.data) {
        const filterFollowers = res?.data?.followers?.filter(
          (e) => e?.status === true
        );
        const filterFollowing = res?.data?.following?.filter(
          (e) => e?.status === true
        );
        setOtherFollowers(filterFollowers);
        setOtherFollowing(filterFollowing);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getOtherFollowers();
  }, []);
  //other profilr
  // eslint-disable-next-line no-unused-vars

  //open popup for block and follow
  // eslint-disable-next-line no-unused-vars
  const morePopupAction = () => {
    const open = openMore;
    setOpenMore(!open);
  };

  const openBlockerPopup = () => {
    setOpenBlockPopup(true);
    setOpenMore(false);
  };
  const closeBlockerPopup = () => {
    setOpenBlockPopup(false);
    setOpenMore(false);
  };
  //count data for follow and unfollow

  const getFollowers = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/followers/${Common.loggedIn()}`
      );
      if (res?.data) {
        const filterFollowers = res?.data?.followers?.filter(
          (e) => e?.status === true
        );
        const filterFollowing = res?.data?.following?.filter(
          (e) => e?.status === true
        );
        setMyFollowers(filterFollowers);
        setMyFollowing(filterFollowing);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const removeFollower = async () => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/xakal/remove/follower`, {
        appUserId: Common.loggedIn(),
        followerId: id,
      });
      setOpenMore(false);
      getOtherFollowers();
      toast.success(`Removed from your Followers list`, {
        autoClose: 5000,
        position: "top-right",
      });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getOtherFollowerss = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/followers/${id}`
      );
      if (res?.data) {
        const filterFollowers = res?.data?.followers?.filter(
          (e) => e?.status === true
        );
        const filterFollowing = res?.data?.following?.filter(
          (e) => e?.status === true
        );
        setOtherFollowersOther(filterFollowers);
        setOtherFollowingOther(filterFollowing);
        const findFollowed = res?.data?.followers?.find(
          (e) => e.followerId === Common.loggedIn()
        );
        const getMystatus = filterFollowing?.find(
          (e) => e?.followerId === Common.loggedIn() && e?.status === true
        );
        setisFollowerForMe(getMystatus ? true : false);
        if (findFollowed && findFollowed?.status === true)
          setFollowingStatus(true);
        else setFollowingStatus(false);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getFollowers();
    if (otherProfile) {
      getOtherFollowerss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateFollowStatus = async (status) => {
    const body = {
      appUserId: Common.loggedIn(),
      followerId: id,
      status: status,
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/follower`, body)
      .then((res) => {});
    getFollowers();
    getOtherFollowerss();
  };
  const getMyRequest = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`
      );
      setRequests(res?.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getMyRequest();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const findItem = requests.find((e) => e?.associateId === id);
    if (findItem) setIsAssociate(true);
    if (findItem && findItem.connectionStatus)
      setAsscStatus(findItem.connectionStatus);
  });

  // eslint-disable-next-line no-unused-vars

  const updateBlockStatus = async () => {
    const statusToUpdate = !blockesdStatus;
    const payload = {
      appUserId: Common.loggedIn(),
      otherProfileId: id,
      status: statusToUpdate,
    };
    try {
      await axios.post(
        `${process.env.PUBLIC_URL}/xakal/block/associate`,
        payload
      );
      getblockedList();
      toast.success(`Profile ${statusToUpdate ? "Blocked" : "UnBlocked"} `, {
        autoClose: 5000,
        position: "top-right",
      });

      setOpenMore(false);
      setOpenBlockPopup(false);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getblockedList = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/block/list/${Common.loggedIn()}`
      );
      if (response?.data) {
        const filterBlocked = response?.data?.blocked.filter(
          (e) => e.status === true
        );
        setBlockedUsers(filterBlocked);
        const isBlocked = filterBlocked?.find((e) => e?.otherProfileId === id);
        setBlockedStatus(isBlocked ? true : false);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getblockedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyConnectionDetailss = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${id}`
      );
      const associatesdataCount = response?.data?.filter(
        (e) => e.connectionStatus === "ASSOCIATE"
      );
      setAssociateCountOther(associatesdataCount?.length);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const sentAssociateInvitation = async () => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/associate`, {
        appUserId: Common.loggedIn(),
        associateId: id,
        connectionStatus: "PENDING",
      })
      .then((res) => {
        getMyConnectionDetailss();
        getMyRequest();
      });
  };
  ///need this code // after prod
  // eslint-disable-next-line no-unused-vars
  const updateInvitation = async () => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: "REJECTED",
      })
      .then((res) => {
        getMyConnectionDetailss();
        getMyRequest();
      });
  };
  ///need this code // after prod
  const revertAssociateInvitation = async () => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/associate`, {
        appUserId: Common.loggedIn(),
        associateId: id,
        connectionStatus: "REJECTED",
      })
      .then((res) => {});
  };

  ///message opening list

  ///need this code // after prod
  const handleMessage = () => {
    setOpenMessage(true);
  };

  const handleChat = (c) => {
    setOpenChat(true);
    setMessageChats(c);
    handleMessagesClick();
    setOpenMessage(false);
  };
  const socket = useRef();
  // const socket = io("ws://localhost:4000");
  // const socket = io("wss://test-staging.thexakal.com")
  const fetchMessagesForConversation = async (conversationId) => {
    try {
      const messageRes = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${conversationId}`
      );
  
      const sortedMessages = messageRes.data.message.sort(
        (a, b) =>
          new Date(b.timestamps.createdAt) - new Date(a.timestamps.createdAt)
      );
  
      const unreadCount = sortedMessages.filter(
        (msg) => !msg.read && msg.sender !== Common.loggedIn()
      ).length;
  
      return { sortedMessages, unreadCount };
    } catch (err) {
      console.log("Error fetching messages for conversation:", err);
      return { sortedMessages: [], unreadCount: 0 };
    }
  };
  
  const fetchConversations = async () => {
    try {
      const conversationRes = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
      );
  
      const conversations = conversationRes.data.conver;
  
      const conversationsWithMessages = await Promise.all(
        conversations.map(async (c) => {
          const { sortedMessages, unreadCount } = await fetchMessagesForConversation(c._id);
          return {
            ...c,
            messages: sortedMessages,
            unreadCount,
          };
        })
      );
  
      const filteredConversations = conversationsWithMessages.filter(
        (convo) => convo.messages.length > 0
      );
  
      const sortedConversations = filteredConversations.sort((a, b) => {
        const latestMessageA = a.messages[0];
        const latestMessageB = b.messages[0];
  
        if (!latestMessageA) return 1;
        if (!latestMessageB) return -1;
  
        return (
          new Date(latestMessageB.timestamps.createdAt) - new Date(latestMessageA.timestamps.createdAt)
        );
      });
  
      setConversation(sortedConversations);
  
      const totalUnreadCount = sortedConversations.reduce(
        (total, convo) => total + convo.unreadCount,
        0
      );
    } catch (err) {
      console.log("Error fetching conversations:", err);
    }
  };
  
  useEffect(() => {
    // Only establish the socket connection once when the component mounts
    if (!socket.current) {
      socket.current = io(process.env.PUBLIC_URL);
    }
  
    // Fetch initial conversations when component loads
    fetchConversations();
  
    // Handle new conversations only if the chat window is open
    const handleNewConversation = async (newConversation) => {
      // Only fetch messages for the new conversation if chat window is open
      if (openMessage && openChat) {
        console.log("New conversation update received:", newConversation);
  
        // Only fetch messages for the new conversation
        const { sortedMessages, unreadCount } = await fetchMessagesForConversation(newConversation._id);
  
        const updatedConversation = {
          ...newConversation,
          messages: sortedMessages,
          unreadCount,
        };
  
        setConversation((prevConversations) => {
          const existingConversation = prevConversations.find(
            (conv) => conv._id === updatedConversation._id
          );
  
          let updatedConversations;
          if (existingConversation) {
            updatedConversations = prevConversations.map((conv) =>
              conv._id === updatedConversation._id ? updatedConversation : conv
            );
          } else {
            updatedConversations = [
              updatedConversation,
              ...prevConversations, // Add to the beginning of the list
            ];
          }
  
          updatedConversations.sort((a, b) => {
            const latestMessageA = a.messages[0];
            const latestMessageB = b.messages[0];
  
            if (!latestMessageA) return 1;
            if (!latestMessageB) return -1;
  
            return (
              new Date(latestMessageB.timestamps.createdAt) - new Date(latestMessageA.timestamps.createdAt)
            );
          });
  
          return updatedConversations;
        });
  
      }
    };
  
    // Listen for real-time conversation updates, but only when chat is open
    socket.current.on("newConversation", handleNewConversation);
  
    // Cleanup the socket listener when the component unmounts or chat is closed
    return () => {
      socket.current.off("newConversation", handleNewConversation); // This ensures that the event listener is removed
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///message chat

  const getAllMessage = async (messageChats) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChats}`
      );
      setMessageChat(res.data.message);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
  }, [messageChats?._id]);
  const userIds = Common.loggedIn();
  const handleSendMessage = () => {
    setOpenConver(true);
  };

  const friendId = messageChats?.members.find((m) => m !== userIds);

  const getUserChat = async (friendId) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`
      );
      setDetail(res.data.aboutOne);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats) {
      getUserChat(friendId);
    }
  }, [friendId]);
  const handleMessagesClick = async () => {
    const payload = {
      conversationId: messageChats?._id,
      userId: userIds,
    };
    try {
      await axios.post(`${process.env.PUBLIC_URL}/xakal/mark-as-read`, payload);
      if (messageChats) {
        getAllMessage(messageChats?._id);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const handleSendMessages = async () => {
    const payload = {
      senderId: userIds,
      receiverId: id,
    };
    try {
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/conversation`,
        payload
      );
      setOpenConver(false);
      fetchConversations();
      handleChat(res.data.newConnection);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const [openResumeSkill, setOpenResumeSkill] = useState(false);
  const handleResumeSkill = () => {
    setOpenResumeSkill(true);
  };
  const [hideMessageButton, setHideMessageButton] = useState(false);
  const [conversationsId, setConversationId] = useState(null);

  useEffect(() => {
    if (otherProfile && conversation?.length > 0) {
      const isMemberInConversation = conversation.some((c) =>
        c.members.includes(id)
      );
      const matchedConversations = conversation.filter((c) =>
        c.members.includes(id)
      );
      setHideMessageButton(isMemberInConversation);
      setConversationId(matchedConversations[0]);
    }
  }, [otherProfile, conversation]);
  const [accountDetail, setAccountDetail] = useState([]);
  const getOneAccount = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn(userId)}`
      );
      setAccountDetail(response.data.account);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (location.state) {
      setEnableService(location.state?.enableService);
      getOneAccount();
      setOpen(location?.state.open);
      setInactives(location?.state.inactive);
    }
  }, [enableServices]);
  const [inputList3, setInputList3] = useState([{ resume: "" }]);
  const [displayedFileNames, setDisplayedFileNames] = useState([""]);
  const [resumeList, setResumeList] = useState([]);

  const handleRemoveClick3 = (index) => {
    const list = [...inputList3];
    list.splice(index, 1);
    setInputList3(list);

    //   updated code
    let previousData = resumeList;
    previousData.splice(index, 1);
    setResumeList(previousData);
  };

  const handleAddClick3 = () => {
    setInputList3([...inputList3, { resume: "" }]);
  };
  const handleInputChangeFile3 = (e, index) => {
    e.preventDefault();
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }

      // Proceed with file selection
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);

      // Update state to show loading popup
      setUploadResumePopup(false);
      setLoadingPopup(true);

      // Simulate upload delay
      setTimeout(() => {
        setLoadingPopup(false);
        setUploadedFile(resume); // Set the uploaded file for preview
        setPreviewPopup(true);
      }, 5000); // Simulated delay of 2 seconds
    }
  };

  const handleInputChange3 = (e, index) => {
    e.preventDefault();
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }

      // Proceed with file selection
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);

      // Update state to show loading popup
      setUploadResumePopup(false);
      setLoadingPopup(true);

      // Simulate upload delay
      setTimeout(() => {
        setLoadingPopup(false);
        setUploadedFile(resume); // Set the uploaded file for preview
        setPreviewPopup(true);
      }, 5000); // Simulated delay of 2 seconds
    }
  };
  const resumeOpen = (id) => {
    setResumeId(id);
    setOpenResume(true);
  };
  const [openResume, setOpenResume] = useState();
  const [resumeId, setResumeId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteResume = async () => {
    setDeleteLoading(true);
    try {
      await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/about/${input.id}/resume/${resumeId}`
      );
      setOpenResume(false);
      prefillValue();
      setDeleteLoading(false);
    } catch (err) {
      console.log("Error fetching details:", err);
    } finally {
      setDeleteLoading(false);
    }
  };
  const [resumeLoading, setResumeLoading] = useState(false);
  const submitAbout = async () => {
    try {
      const resumePayload = [];

      // If there are files in resumeList, take only the most recent one
      if (resumeList?.length > 0) {
        const mostRecentFile = resumeList[resumeList.length - 1]; // Get the latest file (last in the list)

        let resumeUrl;
        let formData = new FormData();
        formData.append("image", mostRecentFile);

        try {
          const res = await axios.post(
            `${process.env.PUBLIC_URL}/xakal/files/`,
            formData
          );
          resumeUrl = res.data.url;
        } catch (error) {
          console.error("Error uploading resume:", error);
        }

        if (resumeUrl) {
          resumePayload.push({
            resume: resumeUrl,
            fileName: mostRecentFile.name,
          });
        }
      } else {
        // If no files in resumeList, use the inputList3 if available
        resumePayload.push(
          ...input.inputList3.filter(
            (e) => e.resume !== "" && e.resume !== undefined
          )
        );
      }

      // Proceed to update the record with the new resume URL
      setResumeLoading(true);

      const params = {
        inputList3: resumePayload,
      };

      try {
        const updateResponse = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
          params
        );

        if (updateResponse.data.success) {
          prefillValue();
          toast.success("Resume Uploaded Successfully!");
          setInputList3(resumePayload);
          setPreviewPopup(false);
          setResumeLoading(false);
        } else {
          console.error("Update failed:", updateResponse.data.message);
        }
      } catch (error) {
        console.error(
          "Error updating item:",
          error.response ? error.response.data : error.message
        );
      }
    } catch (error) {
      console.error(
        "Error in submitAbout function:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    // Initial call on component mount
    fetchUserDetails();

    // Interval for repeated calls (every 1 second)
  }, [fetchUserDetails]);
  const [generatePDF, setGeneratePDF] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleDownloadCertificate = () => {
    setDownloading(true); // Start downloading

    // Temporarily set generatePDF to true to render the Certificate component
    setGeneratePDF(true);

    setTimeout(() => {
      const input = document.getElementById("certificate-content");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Determine the orientation based on the canvas dimensions
        const orientation =
          canvas.width > canvas.height ? "landscape" : "portrait";
        const pdf = new jsPDF(orientation);

        // Set the width and height of the PDF document based on orientation
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");

        // Reset generatePDF state after PDF generation
        setGeneratePDF(false);
        setDownloading(false); // Finish downloading
      });
    }, 500); // Adjust timing as needed to ensure rendering completes
  };
  const handleCancel = () => {
    // Reset file input
    const fileInputs = document.querySelectorAll('input[type="file"]');
    fileInputs.forEach((input) => (input.value = ""));

    // Reset states
    setUploadResumePopup(false);
    setLoadingPopup(false);
    setPreviewPopup(false);
    setUploadedFile(null); // Clear the preview file
    setInputList3(
      inputList3.map((input) => ({ ...input, resume: "", fileName: "" }))
    ); // Reset inputList3
  };
  const [condition, setCondition] = useState(false);
  const previewUrl = uploadedFile ? URL.createObjectURL(uploadedFile) : "";
  useEffect(() => {
    if (location.pathname === "/portal/my-profile") {
      setCondition(true);
    }
  }, []);
  return (
    <div>
      <ToastContainer />
      <Dialog
        open={openConver}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            width: "347px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
      >
        <div className="modalHeader mheader">
          Start Conversation
          <img src={CloseIcon} alt="img" onClick={() => setOpenConver(false)} />
        </div>
        <div className="modalBody">
          <div>Do you want to start the converstion?</div>
        </div>

        <div
          style={{
            position: "relative",
            gap: "10px",
            justifyContent: "center",
            display: "flex",
            marginBottom: "20px",
          }}
        >
          <button className="button3">No</button>
          <button className="button5" onClick={handleSendMessages}>
            yes
          </button>
        </div>
      </Dialog>
      {openMessage && (
        <div className="open-message-div">
          <DialogTitle className="open-message-title">
            <span>Chat</span>
            <AiOutlineCloseCircle onClick={() => setOpenMessage(false)} />
          </DialogTitle>
          <DialogContent>
            {conversation.map((c) => {
              return (
                <>
                  <div
                    onClick={() => {
                      handleChat(c);
                    }}
                  >
                    <Conversation userId={userId} conversation={c} />
                  </div>
                </>
              );
            })}
          </DialogContent>
        </div>
      )}

      {openChat && (
        <div className="open-message-div-chat">
          <div className="modalHeader mheader">
            {messageChats ? (
              <div className="main-div-images">
                <img
                  src={detail?.avatar ? detail.avatar : imageAvatar}
                  alt="images-avatar"
                  className="images-avatar-one"
                />
                <div className="mTitle">{`${detail?.firstName} ${detail?.lastName}`}</div>
              </div>
            ) : (
              ""
            )}
            <img src={CloseIcon} alt="img" onClick={() => setOpenChat(false)} />
          </div>

          <div>
            {messageChats ? (
              <div>
                <MessageChat
                  message={messageChat}
                  userId={userIds}
                  messageChats={messageChats}
                  setMessageChat={setMessageChat}
                  arrivalMessage={arrivalMessage}
                  setArrivalMessage={setArrivalMessage}
                  onCallMessage={getAllMessage}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <Dialog
        open={openMore}
        PaperProps={{
          style: {
            width: "347px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader mheader">
          <div></div>
          <img src={CloseIcon} alt="img" onClick={() => setOpenMore(false)} />
        </div>
        <div>
          {!blockesdStatus ? (
            <div className="modalBody">
              <p onClick={() => openBlockerPopup()}>
                <BlockOutlinedIcon className="block-icon" />
                <span className="mTitle block-cont">Block</span>
              </p>
            </div>
          ) : (
            <div className="modalBody">
              <p onClick={() => openBlockerPopup()}>
                <BlockOutlinedIcon className="block-icon" />
                <span className="mTitle block-cont">UnBlock</span>
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Visby CF,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {isFollowerForMe ? (
              <p onClick={() => removeFollower()}>
                <RemoveCircleOutlineOutlinedIcon
                  style={{ marginRight: "4px", fontSize: "16px" }}
                />
                Remove from followers
              </p>
            ) : null}
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openBlockPopup}
        PaperProps={{
          style: {
            // width: "347px",
            // height: "200px",
            top: "50px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Visby CF,sans-serif",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              fontFamily: "Visby CF,sans-serif",
              gap: "10px",
              cursor: "pointer",
              padding: "20px",
            }}
          >
            {" "}
            <p>
              Blocking this profile will stop the profile from being able to
              interact with you on Xakal. And they can no longer see anything
              you post or even find your profile if they search for it unless
              you unblock this profile and vice vera.
            </p>
            <p>
              Are you sure you want to {blockesdStatus ? "Unblock" : "block"}{" "}
              this profile ?
            </p>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <button
                onClick={() => {
                  closeBlockerPopup();
                  setOpenMore(false);
                }}
                className="button5"
              >
                No
              </button>
              <button onClick={() => updateBlockStatus()} className="button3">
                Yes
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "290px",
            paddingBottom: "20px",
          },
        }}
      >
        <DialogTitle
          className="modalHeader"
          style={{ borderBottom: "0.5px solid #F4F4F6", marginLeft: "20px" }}
        >
          <div>
            <Span>Edit</Span>
          </div>

          <div style={{ cursor: "pointer" }}>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setEditOpen(false)}
              style={{ cursor: "pointer", margin: "10px 20px 10px 0" }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <PopDiv>
            <div
              style={{
                display: "flex",
                gap: "20px",
                cursor: "pointer",
                marginBottom: "20px",
              }}
              onClick={uploadFile}
            >
              <img
                src={Update}
                alt="img"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <TextDiv>Update</TextDiv>
            </div>

            <div
              style={{
                display: "flex",
                gap: "20px",
                cursor: "pointer",
              }}
              onClick={deleteOpenFunction}
            >
              <img
                src={Delete}
                alt="img"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <TextDiv>Delete</TextDiv>
            </div>
          </PopDiv>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteOpen}
        halfWidth
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            padding: "8px 8px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              fontFamily: "Inter-Medium,sans-serif",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
              color: "black",
              // position: "relative",
              top: "5px",
            }}
          >
            Are you sure you want to delete your profile photo?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 30px",
              }}
            >
              <Button5
                onClick={() => setDeleteOpen(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={deleteAvatar}>Yes, Delete</Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={profileSkills} fullWidth maxWidth="md">
        <ProfileSkills
          onClose={() => {
            setProfileSkills(false);
            prefillValue();
          }}
        />
      </Dialog>
      <Dialog open={resumeModal} fullWidth maxWidth="md">
        <ProfileResume
          onClose={() => {
            setResumeModal(false);
            prefillValue();
          }}
        />
      </Dialog>

      <Dialog
        open={about}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { top: "25px" } }}
      >
        <DialogContent>
          <AboutModal
            onClose={() => {
              setAbout(false);
              setImageUploadPopup(false);
              prefillValue();
            }}
            onSave={prefillValue}
            onClick={uploadHandle}
            onFile={openEdit}
            file={selectedImage}
            selectedAvatar={profileImage}
            uploadedImage={profileImage}
          />
        </DialogContent>
      </Dialog>
      {imageUploadPopup && (
        <>
          <ClickOrUpload
            selectedImage={imageUploadedByPopup}
            closeAll={closeImagePopup}
          />
        </>
      )}

      <Dialog open={education} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <EducationModal
            onClose={() => {
              setEducation(false);
              getalledudetails();
            }}
            eduId={editeducation}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={experience} fullWidth maxWidth="md">
        <DialogContent>
          <ExperienceModal
            onClose={() => {
              setExperience(false);
              getallexpdetails();
            }}
            onSave={getallexpdetails}
            expId={editexperience}
            userType={userRoleHr}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={project} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <ProjectModal
            onClose={() => {
              setProject(false);
              getallprodetails();
            }}
            proId={editproject}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={course} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <CourseModal
            onClose={() => {
              setCourse(false);
              getallcourseDetails();
            }}
            courId={editcourse}
          />
        </DialogContent>
      </Dialog>
      <Wrapper className="myprofile-wrapper">
        {/* Start of desktop Header  */}
        {otherProfile ? (
          <div className="header-layout">
            <DesktopHeader
              title={title}
              backbtn
              searchedKey={searchedKey}
              back={backPage}
              otherProfile={otherProfile}
              id={id}
            />
            <MobileHeader hTitle={`${input.firstName} ${input.lastName}`} />
          </div>
        ) : (
          <div className="header-layout">
            <DesktopHeader title={title} searchedKey={searchedKey} />

            <MobileHeader profSetting={true} />
          </div>
        )}

        <div className="in-content-wrapper mp-wrapper">
          <div className="mp-header">
            <div className="mp-i-top">
              <img src={gradBg} className="mp-top-img img-fluid" alt="" />
              <div className="star-holder">
                <span>{totalAverage ? totalAverage.toFixed(1) : 0}</span>
                <img src={star} className="star-img img-fluid" alt="" />
              </div>
            </div>
            <div className="mp-row">
              <div>
                <div className="mp-img-container">
                  {input.avatar ? (
                    <>
                      <div>
                        <img
                          src={input.avatar}
                          className="mp-img img-fluid"
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={imageAvatar}
                        className="mp-img img-fluid"
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className={
                  otherProfile ? "mp-cont-wrapper" : "mp-cont-wrappers"
                }
              >
                <div>
                  <div className="mp-title-row">
                    <h5 className="mp-title mb-0">{`${input.firstName} ${
                      input.middleName ? input.middleName : ""
                    } ${input.lastName}`}</h5>
                  </div>
                  {!otherProfile ? (
                    <p className="mp-desig mb-0">{input.bio}</p>
                  ) : (
                    ""
                  )}
                  <p className="mp-location mb-0">
                    {primaryAddress ? (
                      <>
                        {" "}
                        {primaryAddress?.city},{getcn(primaryAddress?.country)}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  {otherProfile && (
                    <div className="social-cont">
                      <div className="Edu-data-wrapper3">
                        {asscStatus === "PENDING" ? (
                          <div className="social-wrapper">
                            <div className="social-btn social-text">
                              Request Pending
                            </div>
                            <div className="social-btn"></div>
                          </div>
                        ) : null}
                        {asscStatus === "ASSOCIATE" ? (
                          <div
                            className="social-wrapper"
                            onClick={() => updateInvitation()}
                          >
                            <div className="social-btn">Associate</div>
                            <div className="social-btn">
                              <HandshakeOutlinedIcon />
                            </div>
                          </div>
                        ) : null}
                        {asscStatus === null || asscStatus === "REJECTED" ? (
                          <div
                            className="social-wrapper"
                            onClick={() => sentAssociateInvitation()}
                          >
                            <div className="social-btn">Associate</div>
                            <div className="social-btn">
                              <AddOutlinedIcon />
                            </div>
                          </div>
                        ) : null}
                        {followingStatus ? (
                          <div
                            className="social-wrapper"
                            onClick={() => updateFollowStatus(false)}
                          >
                            <div className="social-btn">Following</div>
                            <div className="social-btn">
                              <img src={Followings} alt="img" />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="social-wrapper"
                            onClick={() => updateFollowStatus(true)}
                          >
                            <div className="social-btn">Follow</div>
                            <div className="social-btn">
                              <AddOutlinedIcon />
                            </div>
                          </div>
                        )}
                        {otherProfile && !hideMessageButton ? (
                          <div className="social-wrapper">
                            <div
                              className="social-btn"
                              onClick={handleSendMessage}
                            >
                              Message
                            </div>
                            <div className="social-btn">
                              <img src={Message} alt="img" />
                            </div>
                          </div>
                        ) : (
                          <div className="social-wrapper">
                            <div
                              className="social-btn"
                              onClick={() => handleChat(conversationsId)}
                            >
                              Message
                            </div>
                            <div className="social-btn">
                              <img src={Message} alt="img" />
                            </div>
                          </div>
                        )}
                        {!otherProfile ? (
                          <div
                            className="social-wrapper"
                            onClick={() => setOpenMore(true)}
                          >
                            <div className="social-btn">
                              <MoreVertOutlinedIcon />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* Need this for future enchacement  */}
                <div className="mp-rows">
                  {otherProfile ? (
                    <div className="social-data social-datas">
                      <div
                        className="myp-ass"
                        onClick={() =>
                          history.push("/portal/associatesAndFollowers/" + id, {
                            data: "associates",
                          })
                        }
                      >
                        <div>Associates </div>
                        <div className="social-count">
                          {associateCountOther}{" "}
                        </div>
                      </div>
                      <HRLine />
                      <div
                        className=""
                        onClick={() =>
                          history.push("/portal/associatesAndFollowers/" + id, {
                            data: "followers",
                          })
                        }
                      >
                        <div>Followers </div>
                        <div className="social-count">
                          {otherFollowersOther.length}{" "}
                        </div>
                      </div>
                      <HRLine />
                      <div
                        className=""
                        onClick={() =>
                          history.push("/portal/associatesAndFollowers/" + id, {
                            data: "following",
                          })
                        }
                      >
                        <div>Following </div>
                        <div className="social-count">
                          {otherFollowingOther.length}{" "}
                        </div>
                      </div>
                      <HRLine />
                      {otherProfile ? (
                        ""
                      ) : (
                        <div className="social-wrapper">
                          <div className="social-btn" onClick={handleMessage}>
                            Message
                          </div>
                          <div className="social-btn">
                            <img src={Message} alt="img" />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="social-data social-datas">
                      <div
                        className="myp-ass"
                        onClick={() =>
                          history.push(
                            "/portal/associatesAndFollowers/" +
                              Common.loggedIn(),
                            { data: "associates" }
                          )
                        }
                      >
                        <div>Associates </div>
                        <div className="social-count">{associateCount} </div>
                      </div>
                      <HRLine />
                      <div
                        className="disabledFollow"
                        // onClick={() =>
                        //   history.push(
                        //     "/portal/associatesAndFollowers/" + Common.loggedIn(),
                        //     { data: "followers" }
                        //   )
                        // }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div>Followers </div>
                        <div className="social-count">
                          {otherFollowers.length}{" "}
                        </div>
                      </div>
                      <HRLine />
                      <div
                        className="disabledFollow"
                        // onClick={() =>
                        //   history.push(
                        //     "/portal/associatesAndFollowers/" + Common.loggedIn(),
                        //     { data: "following" }
                        //   )
                        // }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div>Following </div>
                        <div className="social-count">
                          {otherFollowing.length}{" "}
                        </div>
                      </div>
                      <HRLine />
                      {/* {otherProfile ? (
                  ""
                ) : (
                  <div className="social-wrapper">
                    <div className="social-btn" onClick={handleMessage}>
                      Message
                    </div>
                    <div className="social-btn">
                      <img src={Message} alt="img" />
                    </div>
                  </div>
                )} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Wrappers>
            <div
              // className="job-nav-container"
              style={{
                marginBottom: "25px",
                borderRadius: enableService ? "16px 16px 0 0" : "16px",
                borderBottom: enableService ? "1px solid #F4F4F6" : "",
                background: "#FFF",
                paddingLeft: "20px",
              }}
            >
              <div>
                <NativeLink className="job-nav">
                  <div
                    className={
                      shrink && !enableService
                        ? "active job-nav-link"
                        : "job-nav-link"
                    }
                    onClick={handleSection}
                  >
                    About{" "}
                  </div>

                  {userRole ? (
                    <>
                      <div
                        className={
                          enableService
                            ? "active job-nav-link"
                            : "job-nav-link "
                        }
                        onClick={handleService}
                      >
                        Interviews
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      enableRepository
                        ? "active job-nav-link"
                        : "job-nav-link hide"
                    }
                    // className=""
                    onClick={handleRepository}
                  >
                    Repository
                  </div>
                  <div
                    className={
                      postEnable ? "active job-nav-link" : "job-nav-link hide"
                    }
                    // className=""
                    onClick={handlePost}
                  >
                    Post
                  </div>
                </NativeLink>
              </div>
            </div>
            {!enableService ? (
              <div>
                {shrink ? (
                  <div>
                    <div>
                      <div className="mp-card" id="about">
                        <div className="mp-card-header">
                          <h5 className="title mb-0">Personal Information</h5>
                          {!otherProfile ? (
                            <button
                              className="btn btn-mpb"
                              onClick={() => handleClick()}
                            >
                              Update
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="mp-card-body">
                          <div className="mp-about-row">
                            <div className="mp-ap-wrapper">
                              <div className="mp-ap-container">
                                <img
                                  src={
                                    input.avatar ? input.avatar : imageAvatar
                                  }
                                  alt="profile"
                                  className="mp-ap-img img-fluid"
                                />
                              </div>
                            </div>
                            <div>
                              <h5 className="mp-at">
                                {" "}
                                {input.firstName !== ""
                                  ? `${input.firstName} ${
                                      input.middleName ? input.middleName : ""
                                    } ${input.lastName}`
                                  : `Update your name`}
                              </h5>
                              <p className="mp-desig">
                                {input.bio !== ""
                                  ? input.bio
                                  : "Add a bio/headline"}
                              </p>

                              <div className="job-details about-mail mb-3">
                                <div className="icon-title">
                                  <img
                                    src={Email}
                                    className="jt-icon"
                                    alt="icon"
                                  />
                                  <span>
                                    {" "}
                                    {input.email
                                      ? input.email
                                      : "Update your email id."}
                                  </span>
                                </div>

                                {primaryAddress?.city &&
                                primaryAddress?.country ? (
                                  !otherProfile && (
                                    <div className="icon-title">
                                      <img
                                        src={Location}
                                        className="jt-icon"
                                        alt="icon"
                                      />
                                      <span>
                                        {" "}
                                        {primaryAddress?.city &&
                                        primaryAddress?.country
                                          ? `${primaryAddress?.city}, ${getcn(
                                              primaryAddress?.country
                                            )}`
                                          : "Update your location"}
                                      </span>
                                    </div>
                                  )
                                ) : (
                                  <>
                                    {!otherProfile ? (
                                      <div className="icon-title">
                                        <img
                                          src={Location}
                                          className="jt-icon"
                                          alt="icon"
                                        />
                                        <span> "Update your location"</span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>

                              {input.about &&
                              input.about?.length > 0 &&
                              input.about !== "<p><br></p>" ? (
                                <div className="mp-c-card">
                                  <p className="mp-c">
                                    <>
                                      {input.about?.length > 300 ? (
                                        !showMore ? (
                                          parse(
                                            `${input.about.substring(0, 300)}`
                                          )
                                        ) : (
                                          <div className="mp-c">
                                            {parse(input.about)}
                                          </div>
                                        )
                                      ) : (
                                        parse(input.about)
                                      )}
                                      <span
                                        className="rm-btn"
                                        onClick={() => setShowMore(!showMore)}
                                      >
                                        {showMore
                                          ? "Read less..."
                                          : input.about?.length > 200
                                            ? " Read more..."
                                            : ""}
                                      </span>
                                    </>
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="mp-so-row">
                                {input.inputList2 &&
                                  input.inputList2.map((a, i) => {
                                    if (
                                      a.workProfile.trim() !== "" &&
                                      a.urlLink.trim() !== ""
                                    ) {
                                      return (
                                        <div className="mp-c-card" key={i}>
                                          <div className="mp-c-li">
                                            <img
                                              src={SocialLink}
                                              className=""
                                              alt="icon"
                                            />
                                            <a
                                              href={a.urlLink}
                                              className="mp-so-link"
                                              target="_blank"
                                              without
                                              rel="noreferrer"
                                            >
                                              {a.workProfile} {a.urlLink}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return null; // Don't render if workProfile or urlLink is an empty string
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mp-card">
                      <div className="mp-card-header">
                        <h5 className="title mb-5">Skills & Languages</h5>
                        {!otherProfile ? (
                          <button
                            className="btn btn-mpb"
                            onClick={() => handleSkills()}
                          >
                            Update
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="prefer-wraps mb-3">
                        <div class="job-preference">Skills :</div>
                        {mergeSkill?.slice(0, 5).map((item, index) => (
                          <div className="job-preference-pill" key={index}>
                            {item?.label}
                            <div className="star-icon">
                              <div>
                                {item.averageSkillRating
                                  ? item.averageSkillRating?.toFixed(1)
                                  : 0}
                              </div>
                              <img src={Star} alt="img" className="prof-star" />
                            </div>
                          </div>
                        ))}
                        {skills ? (
                          <>
                            {mergeSkill
                              ?.slice(5, skillsLength)
                              .map((item, index) => (
                                <div class="job-preference-pill">
                                  {item?.label}
                                  <div className="star-icon">
                                    <div>
                                      {item.averageSkillRating
                                        ? item.averageSkillRating?.toFixed(1)
                                        : 0}
                                    </div>
                                    <img src={Star} alt="img" />
                                  </div>
                                </div>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                        {!skills ? (
                          <>
                            {mergeSkill?.length > 5 ? (
                              <div
                                class="job-preference-pill job-pref-plus"
                                onClick={() => handleOpen()}
                              >
                                +{mergeSkill?.length - 5}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {mergeSkill?.length > 5 ? (
                              <div
                                class="job-preference-pill job-pref-plus"
                                onClick={() => handleOpen()}
                              >
                                -{mergeSkill?.length - 5}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mp-card">
                      <div className="mp-card-header">
                        <h5 className="title mb-5">Resume</h5>
                      </div>

                      <div>
                        {input &&
                        input?.inputList3 &&
                        input?.inputList3.length > 0 ? (
                          input?.inputList3.map((x, i) => {
                            return x.resume !== "" ? (
                              <>
                                <div>
                                  <div>
                                    <div key={i}>
                                      {x.resume !== "" && (
                                        <InputGroup className="footer-header mb-3 resume-cont">
                                          <img
                                            src={Resume}
                                            className=""
                                            alt="icon"
                                            style={{
                                              margin: "5px 0 20px 20px",
                                            }}
                                          />
                                          <div>
                                            {x.resume ? (
                                              <>
                                                <Form.Control
                                                  id={`fileInputText${i}`}
                                                  className="footer-header mb-3"
                                                  aria-describedby="basic-addon1"
                                                  type="text"
                                                  value={x.fileName}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <div
                                                  id={`fileNamesDisplay${i}`}
                                                ></div>
                                              </>
                                            )}

                                            <Form.Control
                                              id={`fileInput${i}`}
                                              type="file"
                                              name="resume"
                                              onChange={(e) =>
                                                handleInputChange3(e, i)
                                              }
                                              // onBlur={validateInput}
                                              style={{ display: "none" }}
                                            />
                                          </div>
                                          {!otherProfile ? (
                                            <button
                                              className="button2Resume"
                                              onClick={() => {
                                                handleUploadResume();
                                                setIdResumes(i);
                                              }}
                                            >
                                              Upload Resume
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </InputGroup>
                                      )}
                                    </div>
                                  </div>
                                  <div className="resume-icon-wrap">
                                    {!otherProfile ? (
                                      <img
                                        src={Delete}
                                        alt="img"
                                        onClick={() => resumeOpen(x._id)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    ) : (
                                      ""
                                    )}

                                    <img
                                      src={Download}
                                      alt="img"
                                      className="down-img"
                                      onClick={() => {
                                        handleDownload(x.resume, 25);
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            );
                          })
                        ) : (
                          <>
                            {inputList3.map((input, i) => (
                              <div
                                key={i}
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "10px",
                                }}
                              >
                                <img src={Resume} className="" alt="icon" />
                                <label
                                  className="label-name"
                                  style={{
                                    marginTop: "20px",
                                    background: "rgba(244, 244, 246, 1)",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    {!otherProfile ? (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                          background: "rgba(225, 225, 228, 1)",
                                          borderRadius: "8px",
                                          position: "relative",

                                          color: "#362A41",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          document
                                            .getElementById(`formFileLg${i}`)
                                            .click();
                                          setIdsResumes(i);
                                        }}
                                      >
                                        Upload Resume
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </label>
                                <div>
                                  <div>
                                    <input
                                      className="footer-header"
                                      style={{
                                        padding: "10px",
                                        background: "#E1E1E4",
                                        borderRadius: "8px",
                                      }}
                                      id={`formFileLg${i}`}
                                      type="file"
                                      name="resume"
                                      onChange={(e) =>
                                        handleInputChangeFile3(e, i)
                                      }
                                      // onBlur={validateInput}
                                    />
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "12rem",
                                        margin: "10px 0",
                                      }}
                                    >
                                      {" "}
                                      <div id={`fileNameDisplay${i}`}>
                                        {displayedFileNames[i]}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    {inputList3?.length - 1 === i && (
                                      <button
                                        onClick={handleAddClick3}
                                        className="Add-languageprofile"
                                        disabled={
                                          input?.resume === "" ||
                                          input?.resume === undefined
                                        }
                                      ></button>
                                    )}
                                    {inputList3?.length !== 1 && (
                                      <button
                                        className="mr10 Add-language3"
                                        onClick={() => handleRemoveClick3(i)}
                                      >
                                        <img
                                          src={Delete}
                                          alt="img"
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid grey",
                                            padding: "5px",
                                            margin: "0 20px 0 20px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    {/* Education  */}
                    <div className="mp-card" id="education">
                      <div className="mp-card-header mb-3">
                        <h5 className="title mb-0">Education</h5>
                        {!otherProfile ? (
                          <button
                            className="button3"
                            onClick={handleEducationMob}
                          >
                            Add
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mp-card-body">
                        {educationDetail?.length !== 0 ? (
                          <div>
                            {educationDetail?.map((e) => {
                              return (
                                <div className="mp-about-rows">
                                  <div className="mp-ap-wrapper">
                                    <div className="mp-ap-container">
                                      {e.university === "Anna University" ? (
                                        <img
                                          src={
                                            shrink ? EducationOne : imageAvatars
                                          }
                                          alt="profile"
                                          className="mp-ap-img img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={shrink && imageAvatars}
                                          alt="profile"
                                          className="mp-ap-img img-fluid"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="mp-ap-cont-wrapper">
                                    <div className="align-items-center justify-content-between">
                                      <div>
                                        <h5 class="mp-at"> {e.university}</h5>
                                        <p class="mp-desig">
                                          {e.specialization}
                                        </p>
                                        <div className="job-details-wrap mb-3">
                                          <div className="job-details">
                                            <div class="icon-title">
                                              <img
                                                src={gradCap}
                                                className="jt-icon"
                                                alt="icon"
                                              />
                                              <span> {e.degree}</span>
                                            </div>
                                            {e.online === true ? (
                                              <div class="icon-title">
                                                <img
                                                  src={Note}
                                                  className="jt-icon"
                                                  alt="icon"
                                                />
                                                <span> Online</span>
                                              </div>
                                            ) : null}
                                            <div class="icon-title">
                                              <img
                                                src={Suit}
                                                className="jt-icon"
                                                alt="icon"
                                              />
                                              <span>
                                                {" "}
                                                {moment(e.startDate).format(
                                                  "YYYY"
                                                )}{" "}
                                                -{" "}
                                                {moment(e.endDate).format(
                                                  "YYYY"
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            {!otherProfile ? (
                                              <div>
                                                <div className="mp-actions">
                                                  <img
                                                    src={Delete}
                                                    alt="img"
                                                    onClick={() => {
                                                      deledu(e);
                                                    }}
                                                  />

                                                  <img
                                                    src={Edit}
                                                    alt="img"
                                                    onClick={() => {
                                                      handleEditEducation(
                                                        e._id
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            {!otherProfile ? (
                              <h6 className="bio myp-content">
                                Please mention your education details.
                              </h6>
                            ) : (
                              "-"
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Experience */}
                    <div className="mp-card" id="experience">
                      <div className="mp-card-header mb-3">
                        <h5 className="title mb-0">Experience</h5>
                        {!otherProfile ? (
                          <button
                            className="button3"
                            onClick={handleExperienceMob}
                          >
                            Add
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mp-card-body">
                        {experienceDetail?.length !== 0 ? (
                          <div>
                            {experienceDetail?.map((ei, i) => {
                              return (
                                <div>
                                  <div className="mp-about-row">
                                    <div className="mp-ap-wrapper">
                                      <div className="mp-ap-container">
                                        <img
                                          src={
                                            ei.company?.companyLogo
                                              ? ei.company?.companyLogo
                                              : experience_logo
                                          }
                                          alt="profile"
                                          className="mp-ap-img img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div className="mp-ap-cont-wrapper">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <h5 class="mp-at">
                                            {" "}
                                            {ei.designation}
                                          </h5>
                                          <p
                                            class="mp-p-desig"
                                            onClick={() =>
                                              history.push({
                                                pathname: `/portal/other-company-view/${ei.company?.comID}`,
                                              })
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            {ei.company?.value}
                                          </p>
                                          <div className="job-details mb-3">
                                            <div class="icon-title">
                                              <img
                                                src={Suit}
                                                className="jt-icon"
                                                alt="icon"
                                              />
                                              <span>
                                                {" "}
                                                {moment(ei.startDate).format(
                                                  "MMM YYYY"
                                                )}
                                                {ei.endDate === ""
                                                  ? " - Present"
                                                  : ` - ${moment(
                                                      ei.endDate
                                                    ).format("MMM YYYY")}`}
                                              </span>
                                            </div>

                                            <div class="icon-title">
                                              {ei.employeeType !== "" ? (
                                                <>
                                                  <img
                                                    src={Clock}
                                                    className="jt-icon"
                                                    alt="icon"
                                                  />
                                                  <span>{ei.employeeType}</span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div class="icon-title">
                                              {ei.location !== "" ? (
                                                <>
                                                  <img
                                                    src={Location}
                                                    className="jt-icon"
                                                    alt="icon"
                                                  />
                                                  <span>{ei.location}</span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {!otherProfile ? (
                                          <div>
                                            <div className="mp-actions">
                                              <img
                                                src={Delete}
                                                alt="img"
                                                onClick={() => {
                                                  deleExp(ei);
                                                }}
                                              />

                                              <img
                                                src={Edit}
                                                alt="img"
                                                onClick={() => {
                                                  handleEditExperience(ei._id);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {ei &&
                                    ei.description?.length > 0 &&
                                    ei.description !== "<p><br></p>" ? (
                                      <div className="mp-c-card">
                                        <p className="mp-c">
                                          {showcontentexp[i]
                                            ? parse(ei.description)
                                            : parse(
                                                `${ei.description.substring(
                                                  0,
                                                  170
                                                )}`
                                              )}
                                          <span
                                            className="rm-btn"
                                            onClick={() => ToggleContentExp(i)}
                                          >
                                            {showcontentexp[i]
                                              ? "Read less..."
                                              : ei.description?.length > 170
                                                ? " Read more..."
                                                : ""}
                                          </span>
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            {!otherProfile ? (
                              <h6 className="bio myp-content">
                                Please mention your experience details.
                              </h6>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Project */}
                    <div className="mp-card" id="projects">
                      <div className="mp-card-header">
                        <div className="title mb-0">Projects</div>
                        {!otherProfile ? (
                          <button
                            className="button3"
                            onClick={handleProjectMob}
                          >
                            Add
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mp-card-body">
                        {projectDetail?.length !== 0 ? (
                          <>
                            {projectDetail?.map((pro, i) => {
                              return (
                                <div className="mp-about-row">
                                  <div className="mp-ap-cont-wrapper">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div>
                                        <div
                                          className="mt-4 mb-3"
                                          style={{ gap: "15px" }}
                                        >
                                          <h5 className="mp-at mb-0">
                                            {pro.projectTitle}
                                          </h5>

                                          <div className="job-details">
                                            <div className="icon-title">
                                              <img
                                                src={Suit}
                                                className="jt-icon"
                                                alt="icon"
                                              />
                                              <span>
                                                {" "}
                                                {pro.startDate} - {pro.endDate}
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="prefer-wraps mb-3">
                                          <div class="job-preference">
                                            Tools / Framework:
                                          </div>
                                          {pro.tool.map((tool, index) => (
                                            <div
                                              className="job-preference-pill"
                                              key={index}
                                            >
                                              {tool?.value}
                                            </div>
                                          ))}
                                        </div>
                                        <div class="prefer-wraps mb-3">
                                          <div class="job-preference">
                                            Skills:
                                          </div>
                                          {pro.skill.map((skill, index) => (
                                            <div
                                              className="job-preference-pill"
                                              key={index}
                                            >
                                              {skill?.value}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      {!otherProfile ? (
                                        <div className="mp-actions">
                                          <img
                                            src={Delete}
                                            alt="img"
                                            onClick={() => {
                                              delePro(pro);
                                            }}
                                          />

                                          <img
                                            src={Edit}
                                            alt="img"
                                            onClick={() => {
                                              handleEditProject(pro._id);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {pro &&
                                    pro.description?.length > 0 &&
                                    pro.description !== "<p><br></p>" ? (
                                      <div className="mp-c-card">
                                        <p className="mp-c">
                                          <>
                                            {showcontentpro[i]
                                              ? parse(pro.description)
                                              : parse(
                                                  `${pro.description.substring(
                                                    0,
                                                    300
                                                  )}`
                                                )}
                                            <span
                                              className="rm-btn"
                                              onClick={() =>
                                                ToggleContentPro(i)
                                              }
                                            >
                                              {showcontentpro[i]
                                                ? "Read less..."
                                                : pro.description?.length > 300
                                                  ? " Read more..."
                                                  : ""}
                                            </span>
                                          </>
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {!otherProfile ? (
                              <h6 className="bio myp-content">
                                Please mention the projects you have worked on.
                              </h6>
                            ) : (
                              "-"
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Skills */}
                    <div className="mp-card" id="courses">
                      <div className="mp-card-header mb-3">
                        <h5 className="title">Courses / Certifications</h5>
                        {!otherProfile ? (
                          <button className="button3" onClick={handleCourseMob}>
                            Add
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mp-card-body">
                        {!otherProfile && (
                          <>
                            {courseDetail?.findIndex(
                              (e) => e.event === "Xakal"
                            ) !== -1 && (
                              <div className="button-main-download">
                                <button
                                  className="buttonOne"
                                  onClick={handleDownloadCertificate}
                                  disabled={downloading} // Disable button during download
                                >
                                  {downloading ? (
                                    <span>Downloading...</span>
                                  ) : (
                                    " Download Certificate"
                                  )}
                                </button>

                                {/* Display loading text */}
                              </div>
                            )}
                          </>
                        )}

                        {courseDetail?.length !== 0 ? (
                          <div>
                            {courseDetail?.map((e) => (
                              <>
                                <div className="mp-about-row" key={e._id}>
                                  <div className="mp-ap-wrapper">
                                    <div className="mp-ap-container">
                                      <img
                                        src={imageAvatar}
                                        alt="profile"
                                        className="mp-ap-img img-fluid"
                                      />
                                    </div>
                                  </div>
                                  <div className="mp-ap-cont-wrapper">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <h5 className="mp-at">
                                          {e.course}{" "}
                                          {e.online === true ? (
                                            <span>(Online)</span>
                                          ) : null}
                                        </h5>
                                        <div className="job-details mb-3">
                                          <div className="icon-title">
                                            <img
                                              src={Suit}
                                              className="jt-icon"
                                              alt="icon"
                                            />
                                            <span>
                                              {e.startDate} - {e.endDate}
                                            </span>
                                          </div>

                                          <div className="icon-title">
                                            <img
                                              src={Suit}
                                              className="jt-icon"
                                              alt="icon"
                                            />
                                            <span>
                                              {e.issueDate} - {e.validity}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="prefer-wraps mb-3">
                                          <div className="job-preference">
                                            Skills:
                                          </div>
                                          {e.skill.map((skill, index) => (
                                            <div
                                              className="job-preference-pill"
                                              key={index}
                                            >
                                              {skill?.value}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      {!otherProfile && e.event !== "Xakal" && (
                                        <div>
                                          <div className="mp-actions">
                                            <img
                                              src={Delete}
                                              alt="Delete"
                                              onClick={() => deleCourse(e)}
                                            />
                                            <img
                                              src={Edit}
                                              alt="Edit"
                                              onClick={() =>
                                                handleEditCourse(e._id)
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          <>
                            {!otherProfile ? (
                              <h6 className="bio myp-content">
                                Please provide information on the courses you
                                have completed and the certification you have
                                attended.
                              </h6>
                            ) : (
                              "-"
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div onClick={handleMessage}>
                      <ChatMain />
                    </div> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : otherProfile ? (
              <OtherService id={id} input={input} />
            ) : (
              <ManageInterview
                input={input}
                experience={experienceDetail}
                education={educationDetail}
                accountDetail={accountDetail}
                handleSection={() => handleClick()}
                handleSkill={() => handleResumeSkill()}
                checkActive={
                  location?.state?.goInActive === true ? "inactive" : "active"
                }
                enableService={enableService}
                inactives={inactives}
                opens={open}
                cond={condition}
              />
            )}
            {enableRepository ? (
              <div className="repo-wrapper">
                <div className="repo-header">
                  {showcased ? (
                    <h5 className="repo-title mb-0">Interviews Conducted</h5>
                  ) : !otherProfile ? (
                    <h5 className="repo-title mb-0">Archived</h5>
                  ) : (
                    <></>
                  )}
                  {!otherProfile ? (
                    <div className="btn-repo-container">
                      <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        value={value}
                        onChange={handleChanges}
                      >
                        <Tab
                          value="showcased"
                          label="showcased"
                          // className="btn btn-repo"
                          onClick={() => handleShowcase()}
                        >
                          Showcased recordings
                        </Tab>
                        <Tab
                          value="archive"
                          label="Archive"
                          // className="btn btn-repo"
                          onClick={() => handleArchive()}
                        >
                          Archived recordings
                        </Tab>
                      </Tabs>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {showcased ? (
                  <>
                    <div className="repo-body">
                      <Slider {...settings}>
                        {hostRecordings.map((el) => (
                          <div className="repo-card">
                            <video
                              src={el?.recording?.recordingUrl}
                              className="img-fluid rc-img"
                              alt=""
                            />
                            <div
                              className="play-icons play-myp"
                              onClick={() =>
                                history.push(
                                  `/portal/MyProfile/repoDetails/${el?.recording?.sessionId}`,
                                  {
                                    state: {
                                      recording: el,
                                      otherProfile: otherProfile,
                                      id: userId,
                                    },
                                  }
                                )
                              }
                            >
                              <img
                                src={PlayIcon}
                                alt="img"
                                style={{ width: "30px" }}
                              />
                              <div className="play-icons-text">Play</div>
                            </div>
                            <div className="rc-cont">
                              <h5
                                className="rc-title mb-0"
                                onClick={() =>
                                  history.push(
                                    `/portal/MyProfile/repoDetails/${el?.recording?.sessionId}`,
                                    {
                                      state: {
                                        recording: el,
                                        otherProfile: otherProfile,
                                        id: userId,
                                      },
                                    }
                                  )
                                }
                              >
                                {el.eventTitle}
                              </h5>
                              {!otherProfile ? (
                                <Dropdown className="rc-dropdown flex-shrink-0">
                                  <Dropdown.Toggle
                                    className="rc-option"
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={optionDots}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rc-dropdown-menu">
                                    <Dropdown.Item
                                      onClick={() =>
                                        archieveRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                    >
                                      Move to archived repository
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        deleteRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                      className="delete-link"
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => openPopup(el)}
                                    >
                                      Share Repository
                                      <BsShareFill size={10} className="ml-3" />
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>

                    <div className="repo-header">
                      <h5 className="repo-title mb-0">Interviews Attended</h5>
                    </div>

                    <div className="repo-body">
                      <Slider {...settings}>
                        {guestRecordings.map((el) => (
                          <div className="repo-card">
                            <video
                              src={el?.recording?.recordingUrl}
                              className="img-fluid rc-img"
                              alt=""
                            />
                            <div
                              className="play-icons"
                              onClick={() =>
                                history.push(
                                  `/portal/MyProfile/repoDetails/${el?.recording?.sessionId}`,
                                  {
                                    state: {
                                      recording: el,
                                      otherProfile: otherProfile,
                                      id: userId,
                                    },
                                  }
                                )
                              }
                            >
                              <img
                                src={PlayIcon}
                                alt="img"
                                style={{ width: "30px" }}
                              />
                              <div className="play-icons-text">Play</div>
                            </div>
                            <div className="rc-cont">
                              <h5
                                className="rc-title mb-0"
                                onClick={() =>
                                  history.push(
                                    `/portal/MyProfile/repoDetails/${el?.recording?.sessionId}`,
                                    {
                                      state: {
                                        recording: el,
                                        otherProfile: otherProfile,
                                        id: userId,
                                      },
                                    }
                                  )
                                }
                              >
                                {el.eventTitle}
                              </h5>
                              {!otherProfile ? (
                                <Dropdown className="rc-dropdown flex-shrink-0">
                                  <Dropdown.Toggle
                                    className="rc-option"
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={optionDots}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rc-dropdown-menu">
                                    <Dropdown.Item
                                      onClick={() =>
                                        archieveRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                    >
                                      Move to archived repository
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        deleteRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                      className="delete-link"
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => openPopup(el)}
                                    >
                                      <BsShareFill size={10} />
                                      Share Repository
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </>
                ) : !otherProfile ? (
                  <>
                    <div className="repo-body">
                      <Slider {...settings}>
                        {archievedrecordings.map((el) => (
                          <div className="repo-card">
                            <video
                              src={el?.recording?.recordingUrl}
                              className="img-fluid rc-img"
                              alt=""
                            />
                            <div className="rc-cont">
                              <h5
                                className="rc-title mb-0"
                                onClick={() =>
                                  history.push(
                                    `/portal/MyProfile/repoDetails/${el?.recording?.sessionId}`,
                                    {
                                      state: {
                                        recording: el,
                                        otherProfile: otherProfile,
                                        id: userId,
                                      },
                                    }
                                  )
                                }
                              >
                                {el.eventTitle}
                              </h5>
                              {!otherProfile ? (
                                <Dropdown className="rc-dropdown flex-shrink-0">
                                  <Dropdown.Toggle
                                    className="rc-option"
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={optionDots}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rc-dropdown-menu">
                                    <Dropdown.Item
                                      onClick={() =>
                                        unArchieveRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                    >
                                      Move to showcased repository
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        deleteRecording(
                                          el?.recording?.sessionId
                                        )
                                      }
                                      className="delete-link"
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => openPopup(el)}
                                    >
                                      <BsShareFill size={10} />
                                      Share Repository
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : null}
            {postEnable ? <SocialMediaPost input={input} /> : null}
          </Wrappers>
        </div>
      </Wrapper>

      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "auto",
            top: "50px",
            right: "35px",
            borderRadius: "32px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 10px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>

          <img
            src={CloseIcon}
            alt="img"
            style={{ position: "relative", bottom: "10px", cursor: "pointer" }}
            onClick={() => setOpenSetting(false)}
          />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
              marginLeft: "20px",
            }}
          >
            <img src={ChangePassword} alt="img" />
            <ChangePass onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </ChangePass>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
              marginLeft: "10px",
              padding: "10px",
            }}
            onClick={onSubmit2}
          >
            <img src={Loggedout} alt="img" />
            <Logout> Logout</Logout>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>

      <Dialog open={contactDetails} fullWidth maxWidth="sm">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
            }}
          >
            {experienceDetail
              .filter((exp) => !exp.endDate && exp.timestamps.createdAt)
              .sort(
                (exp1, exp2) =>
                  new Date(exp2.timestamps.createdAt) -
                  new Date(exp1.timestamps.createdAt)
              )
              .slice(0, 1)
              .map((exp) => (
                <div
                  key={exp._id}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={
                      exp.company?.companyLogo
                        ? exp.company?.companyLogo
                        : Education
                    }
                    alt="Education"
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                  <div>
                    <span>{exp.company?.value}</span>
                  </div>
                </div>
              ))}
          </span>
          <AiOutlineCloseCircle onClick={() => setContactDetails(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="Edu-data-wrapper3">
              <div className="contact-hr"></div>
              <div
                className="myp-contact"
                style={{ fontSize: "24px", marginLeft: "-8px" }}
              >
                {`${input.firstName} ${
                  input.middleName ? input.middleName : ""
                } ${input.lastName}`}
              </div>
            </div>

            {experienceDetail
              .filter((exp) => !exp.endDate && exp.timestamps.createdAt)
              .sort(
                (exp1, exp2) =>
                  new Date(exp2.timestamps.createdAt) -
                  new Date(exp1.timestamps.createdAt)
              )
              .slice(0, 1)
              .map((a) => (
                <div key={a._id} className="myp-contact-desig">
                  {a.designation}
                </div>
              ))}
            <div className="contact-wrapper">
              <div className="Edu-data-wrapper">
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={Contactmail} alt="img" />
                  </div>
                  <div className="contact-details" onClick={handleProfileClick}>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px",
                        marginRight: "-6px",
                      }}
                    >
                      <div>
                        xakal.com/{`${input.firstName} ${input.lastName}`}
                      </div>

                      <div>
                        {isCopied ? (
                          <MdCheck size={20} style={{ color: "green" }} />
                        ) : (
                          <MdContentCopy
                            size={20}
                            onClick={handleProfileClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={ContactWebsite} alt="img" />
                  </div>
                  <div className="contact-details">{input.email}</div>
                </div>
              </div>
              <div className="Edu-data-wrapper contact-container">
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={Contactjoin} alt="img" />
                  </div>
                  <div className="contact-details">
                    Associated on {formatDate(input.createdDate)}
                  </div>
                </div>
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={ContactNo} alt="img" />
                  </div>
                  <div className="contact-details">{input.phoneNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody share-repo">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <HiOutlineMail
                size={24}
                onClick={handleEmailShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <CiLinkedin
                size={24}
                onClick={handleLinkedInShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy
                  size={24}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              )}
              <h4 className="sb-text">{isCopieds ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openResume}
        PaperProps={{
          style: {
            borderRadius: "16px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            padding: "5px 8px",
            height: "fit-content",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
            left: "5px",
          }}
        >
          <IconWrapper>
            <img src={DeleteImg} alt="img" />
          </IconWrapper>

          <JobTitle>Are you sure you want to delete this resume ?</JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                marginLeft: "2rem",
                marginRight: "10px",
              }}
            >
              <Button4 onClick={() => setOpenResume(false)}>No</Button4>
              <Button6 onClick={deleteResume}>
                {deleteLoading ? <Spinner /> : "Yes, Delete"}
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={uploadResumePopup}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "16px",

            padding: "5px 8px",
            height: "fit-content",
          },
        }}
      >
        <DialogTitle>
          <div className="upload-resume-head">
            <img src={CloseIcon} alt="img" onClick={handleCancel} />
          </div>
        </DialogTitle>
        <div>
          <div className="upload-resume-main">
            <img src={currentResume} alt="currentResume" />
          </div>
          <div className="content-resume-div">
            <h3>Replace Current Resume ?</h3>
            <p>
              Selecting a new resume will remove the current one from your
              profile
            </p>
          </div>
          <div className="button-resume">
            <button onClick={handleCancel} className="cancelButtonResume">
              Cancel
            </button>
            <button
              className="confirmButtonResume"
              onClick={() => {
                document.getElementById(`fileInput${resumeIds}`)?.click();

                document.getElementById(`formFileLg${resumesIds}`)?.click();
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={loadingPopup}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent style={{ overflow: "hidden" }}>
          <img
            src={uploadResumeSvg}
            alt="currentResume"
            className="upload-resume-main"
          />
          <div
            className="content-resume-div"
            style={{ bottom: "20px", position: "relative" }}
          >
            <h3>Uploading Resume ?</h3>
            <p>This may take a few moments</p>
          </div>
          <div className="button-resume" tyle={{ bottom: "5px" }}>
            <button className="confirmButtonResume">
              <Spinner />
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Resume Preview Popup */}
      <Dialog
        open={previewPopup}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "5px 8px",
            height: "auto",
          },
        }}
      >
        <DialogTitle>
          <div className="upload-resume-head">
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setPreviewPopup(false)}
            />
          </div>
        </DialogTitle>
        <div>
          {uploadedFile && (
            <div>
              {/* <h3>Uploaded File Preview:</h3> */}
              <div style={{ margin: "45px 45px 34px 45px" }}>
                <iframe
                  src={previewUrl}
                  width="100%"
                  height="400px"
                  style={{ border: "none" }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="button-resume btn-resume">
          <button onClick={handleCancel} className="cancelButtonResume">
            Cancel
          </button>
          <button className="confirmButtonResume" onClick={submitAbout}>
            {resumeLoading ? <Spinner /> : "Continue"}
          </button>
        </div>
        <button
          className="confirmButtonAnother"
          onClick={() => {
            handleUploadResume();

            setPreviewPopup(false);
          }}
        >
          Replace another file instead
        </button>
      </Dialog>
      {generatePDF && (
        <div id="certificate-content">
          <Certificate values={input} />
        </div>
      )}
    </div>
  );
};

export default MyProfile;

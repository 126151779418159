/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import "./textMedia.css";
import companyLogo from "../../images/profile-image.svg";
import { Dialog, DialogContent } from "@mui/material";
import Toggle from "react-toggle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Calender from "../../images/Calendar.svg";
import axios from "axios";
import Common from "../../utils/common";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhotoVideo, FaPoll } from "react-icons/fa";
import { FaVideo } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdWork } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { CiStreamOn } from "react-icons/ci";
import { FaPodcast } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { RiArticleLine } from "react-icons/ri";
import { FaBookOpen } from "react-icons/fa";
import { TbBookFilled } from "react-icons/tb";
import { AiOutlineSnippets } from "react-icons/ai";
import CloseIcon from "../../images/CloseIcon.svg";
import "react-quill/dist/quill.snow.css";
import EbookMediaForm from "./ebookMediaForm";
import PollMediaForm from "./pollMediaForm";
import PodCastForm from "./podcastForm";
import PhotoFormMedia from "./photoFormMedia";
import VideoFormMedia from "./videoFormMedia";
import ArticleFormMedia from "./articleFormMedia";
import EventFormMedia from "./eventFormMedia";
import SnippetFormMedia from "./snipperFormMedia";
import VideoDialogTrim from "./videoDialogTrim";
import CourseMediaForm from "./courseMediaForm";

const TextCard = ({ activeTab, input, getAll }) => {
  const [inputValue, setInputValue] = useState("");
  const [invitations, setInvitations] = useState([]);
  const [openToggle, setOpenToggle] = useState(false);
  const [openPhotoForm, setOpenPhotoForm] = useState(false);
  const [openVideoForm, setOpenVideoForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [scheduleToggle, setScheduleToggle] = useState(false);
  const [openSchedulePost, setOpenSchedulePost] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false); // Controls date picker visibility
  const [privacy, setPrivacy] = useState("ASSOCIATE");
  const [images, setImages] = useState([]);
  const [imagesCover, setImagesCover] = useState(null);
  const [videos, setVideos] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const [articleOpen, setArticleOpen] = useState(false);
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  //article
  const [articleTitle, setArticleTitle] = useState("");
  const [articleDescription, setArticleDescription] = useState("");
  const [ebookOpen, setEbookOpen] = useState(false);
  //ebook

  //end of ebook
  const handleEbookOpen = () => {
    setEbookOpen((prevState) => !prevState);
  };
  const handleArticleForm = () => {
    setArticleOpen((prevState) => !prevState);
  };
  const handlePhotoOpen = () => {
    setOpenPhotoForm((prevState) => !prevState);
  };
  const handleVideoOpen = () => {
    setOpenVideoForm((prevState) => !prevState);
  };
  const handleMore = () => {
    setOpenMore((prevState) => !prevState);
  };
  const fileInputRef = useRef(null);
  const fileInputVideoRef = useRef(null);
  const fileInputCoverRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const timezones = moment.tz.names().map((tz) => {
    const offsetInMinutes = moment.tz(tz).utcOffset();
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const minutes = (Math.abs(offsetInMinutes) % 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const sign = offsetInMinutes >= 0 ? "+" : "-";
    const formattedOffset = `GMT ${sign}${hours}:${minutes}`;

    return {
      name: `${tz} (${formattedOffset})`,
      label: `${tz} (${formattedOffset})`,
    };
  });
  // eslint-disable-next-line no-unused-vars
  const formattedDate = selectedDate
    ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
    : null;
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleToggle = () => {
    setOpenToggle(true);
  };

  const handleToggleOne = () => {
    setScheduleToggle((prevState) => !prevState);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Do something with the selected date
  };
  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
    <div className="custom-datepicker-header">
      <button onClick={decreaseMonth}>{"<"}</button>
      <span>{date.toLocaleDateString()}</span>
      <button onClick={increaseMonth}>{">"}</button>
      <div style={{ display: "flex", gap: "20px", left: "10px" }}>
        <button onClick={() => setOpen(false)} style={{ fontSize: "20px" }}>
          Back
        </button>
      </div>
    </div>
  );
  const handlePrivacyChange = () => {
    setPrivacy((prevPrivacy) =>
      prevPrivacy === "ASSOCIATE" ? "PUBLIC" : "ASSOCIATE"
    );
  };
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const currentDate = new Date();
  const formattedDates = formatDate(currentDate);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Prepare media uploads
      const mediaUploads = [];

      // Handle image uploads if there are any
      if (images.length > 0) {
        const imageUploads = await Promise.all(
          images.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Handle video uploads if there are any
      if (videos.length > 0) {
        const videoUploads = await Promise.all(
          videos.map(async (file) => {
            const formData = new FormData();
            formData.append("video", file);

            // Upload video and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...videoUploads.flat()); // Flatten and add to mediaUploads
      }

      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle ? formattedDate : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // Set media to the uploaded media array
        shortDesc: shortDesc,
        longDesc: longDesc,
      };

      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images after submission
      setVideos([]); // Clear videos after submission
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlevideoSubmit = async () => {
    setIsLoading(true);
    try {
      // Prepare media uploads
      const mediaUploads = [];

      // Handle image uploads if there are any

      // Handle video uploads if there are any
      if (videos.length > 0) {
        const videoUploads = await Promise.all(
          videos.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload video and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...videoUploads.flat()); // Flatten and add to mediaUploads
      }

      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // Set media to the uploaded media array
        shortDesc: shortDesc,
        longDesc: longDesc,
      };

      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images after submission
      setVideos([]); // Clear videos after submission
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
        setInvitations(dt);
      });
  };
  useEffect(() => {
    getMyInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (invitations.length > 0) {
      getAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setImages((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };

  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Filter for valid video formats (mp4 and mp3)
    const validVideos = files.filter((file) => file.type === "video/mp4");

    // Check for invalid file formats
    const invalidFiles = files.filter((file) => file.type !== "video/mp4");

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos
    const totalSize = validVideos.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 200 MB
    if (totalSize > 200 * 1024 * 1024) {
      toast.error(
        "Total video size exceeds 200 MB. Please select fewer videos."
      );
      return;
    }

    // If everything is valid, update the state with the new videos
    setVideos((prevVideos) => [...prevVideos, ...validVideos]);
  };

  // In your render method:
  const handleDivCoverClick = () => {
    fileInputCoverRef.current?.click();
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleDivVideoClick = () => {
    fileInputVideoRef.current.click();
  };
  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };
  const removeVideo = (index) => {
    setVideos(images.filter((_, i) => i !== index));
  };
  const getArticleTitleText = () => {
    return articleDescription.replace(/<[^>]+>/g, "").trim(); // Removes HTML tags and trims the text
  };
  const handleCoverChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImagesCover(file); // Store a single image (not in an array)
  };

  const handleArticleSubmit = async () => {
    setIsLoading(true);
    try {
      let imageUrl = null; // Variable to store the uploaded image URL

      // Handle image upload if there is a single cover image
      if (imagesCover) {
        const formData = new FormData();
        formData.append("image", imagesCover); // Append the single image file

        // Upload image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imageUrl = response.data.url; // Get the image URL from the response
      }

      const mediaUploads = [];

      // Handle image uploads if there are any images
      if (images && images.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          images.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Construct the payload with the uploaded image URL
      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle ? formattedDates : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // If image exists, add to media
        shortDesc: shortDesc,
        longDesc: longDesc,
        coverPhoto: imageUrl, // Add the uploaded cover photo URL
        articleTitle: articleTitle,
        articleDescription: articleDescription,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states after submission
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      setArticleOpen(false);
      setArticleTitle("");
      setArticleDescription("");
      setShortDesc("");
      setLongDesc("");
      setImagesCover("");
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  ///ebook form
  const [file, setFile] = useState([]);
  const [bookCover, setBookCover] = useState();
  const [bookPhoto, setBookPhoto] = useState([]);
  const [bookVideo, setBookVideo] = useState([]);
  const [bookTitle, setBookTitle] = useState("");

  const fileInputBookFileRef = useRef(null);
  const fileInputBookPhotoRef = useRef(null);
  const fileInputBookVideoRef = useRef(null);
  const fileInputBookCoverRef = useRef(null);
  const handleEbookImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setBookPhoto((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };

  const handleEbookVideoChange = (e) => {
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Filter for valid video formats (mp4 and mp3)
    const validVideos = files.filter(
      (file) => file.type === "video/mp4" || file.type === "audio/mpeg"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => file.type !== "video/mp4" && file.type !== "audio/mpeg"
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos
    const totalSize = validVideos.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 200 MB
    if (totalSize > 200 * 1024 * 1024) {
      toast.error(
        "Total video size exceeds 200 MB. Please select fewer videos."
      );
      return;
    }

    // If everything is valid, update the state with the new videos
    setBookVideo((prevVideos) => [...prevVideos, ...validVideos]);
  };
  const handleEbookFileChange = (e) => {
    debugger;
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Define allowed formats for different file types
    const allowedFormats = {
      document: ["application/pdf", "application/msword"],
    };

    // Filter for valid files
    const validFiles = files.filter((file) =>
      allowedFormats.document.includes(file.type)
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => !allowedFormats.document.includes(file.type)
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4, MP3, PDF, and DOC formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos and audio files
    const totalVideoAudioSize = validFiles
      .filter((file) => allowedFormats.document.includes(file.type))
      .reduce((acc, file) => acc + file.size, 0);

    // Check if the total size of videos and audios exceeds 12 MB
    if (totalVideoAudioSize > 12 * 1024 * 1024) {
      toast.error(
        "Total video/audio size exceeds 200 MB. Please select fewer files."
      );
      return;
    }

    // If everything is valid, update the state with the new files
    setFile((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // In your render method:

  const handleDivBookFileClick = () => {
    fileInputBookFileRef.current.click();
  };
  const handleDivBookPhotoClick = () => {
    fileInputBookPhotoRef.current.click();
  };
  const handleDivBookVideoClick = () => {
    fileInputBookVideoRef.current.click();
  };
  const handleDivBookCoverClick = () => {
    fileInputBookCoverRef.current.click();
  };
  const removeBookImage = (index) => {
    setBookPhoto(bookPhoto.filter((_, i) => i !== index));
  };
  const removeBookVideo = (index) => {
    setBookVideo(bookVideo.filter((_, i) => i !== index));
  };
  const removeBookFile = (index) => {
    setFile(file.filter((_, i) => i !== index));
  };
  const handleBookCoverChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setBookCover(file); // Store a single image (not in an array)
  };
  const handleEbookSubmit = async () => {
    setIsLoading(true);
    try {
      let imageUrl = null; // Variable to store the uploaded image URL

      // Handle image upload if there is a single cover image
      if (bookCover) {
        const formData = new FormData();
        formData.append("image", bookCover); // Append the single image file

        // Upload image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imageUrl = response.data.url; // Get the image URL from the response
      }

      const bookVideoUpload = [];

      // Handle image uploads if there are any images
      if (bookVideo && bookVideo.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          bookVideo.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookVideoUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      const bookImageUpload = [];

      // Handle image uploads if there are any images
      if (bookPhoto && bookPhoto.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          bookPhoto.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookImageUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      const bookFileUpload = [];

      // Handle image uploads if there are any images
      if (file && file.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          file.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookFileUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      // Construct the payload with the uploaded image URL
      const payload = {
        category: activeTab,
        bookTitle: bookTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },

        shortDesc: shortDesc,
        longDesc: longDesc,
        coverPhoto: imageUrl, // Add the uploaded cover photo URL

        videoBookMedia: bookVideoUpload,
        imageBookMedia: bookImageUpload,
        fileBookMedia: bookFileUpload,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states after submission
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      setEbookOpen(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  //end of ebook

  //pollstart
  const [pollOpen, setPollOpen] = useState(false);
  const [pollTitle, setPollTitle] = useState("");
  const [pollCaption, setPollCaption] = useState("");
  const [pollOptions, setPollOptions] = useState([
    { optionText: "", votes: 0 },
  ]);
  const handlePoleSubmit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        category: activeTab,
        pollTitle: pollTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },

        pollCaptions: pollCaption,
        optionsPoll: pollOptions,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );
      setPollTitle("");
      setPollCaption("");
      setPollOptions([]);
      setPollOpen(false);
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  ///podcast flow
  const [podcastOpen, setPodcastOpen] = useState(false);
  const [podcastTitle, setPodcastTitle] = useState("");
  const [eventFormat, setEventFormat] = useState("");
  const [imagesVideo, setImageVideo] = useState(null);
  const [imagesAudio, setImageAudio] = useState(null);
  const [userArray, setUserArray] = useState([]);
  const [imageCoverPodcast, setImagesCoverPodcast] = useState(null);
  const [userSelect, setUserSelect] = useState([]);
  const optionFormat = [
    { label: "Audio", value: "Audio" },
    { label: "Video", value: "Video" },
  ];
  const handlePodcastTitle = (e) => {
    setPodcastTitle(e.target.value);
  };
  const handleFormatChange = (e) => {
    setEventFormat(e);
  };
  const fileInputPodcastRef = useRef();

  const handleDivPodcastClick = () => {
    fileInputPodcastRef.current.click();
  };
  const fileInputPodcasImagetRef = useRef();
  const fileInputPodcastAudioRef = useRef();

  const handleDivPodcastImageClick = () => {
    fileInputPodcasImagetRef.current.click();
  };
  const handleDivPodcastAudioClick = () => {
    fileInputPodcastAudioRef.current.click();
  };
  const handleVideoPodcast = (e) => {
    const file = e.target.files[0]; // Get the first file (single video/audio file)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid video/audio formats (mp4, mp3)
    const validMediaTypes = ["video/mp4", "audio/mp3"];
    if (!validMediaTypes.includes(file.type)) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Check if the file size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      toast.error("File size exceeds 12 MB. Please select a smaller file.");
      return;
    }

    // Update the state with the selected media file
    setImageVideo(file); // Store a single media file
  };
  const userIdSelect = userSelect.map((user) => ({
    name: user.name,
    userId: user.userId,
  }));
  const handleCoverPodcastChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImagesCoverPodcast(file); // Store a single image (not in an array)
  };
  const handleAudioPodcast = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImageAudio(file); // Store a single image (not in an array)
  };
  const handlePodCastSubmit = async () => {
    setIsLoading(true);
    try {
      let imagePodcast = null; // Store the uploaded cover image URL
      let podcastMedia = null; // Store the media URL (video or audio)

      // Handle cover image upload if there's a single cover image
      if (imageCoverPodcast) {
        const formData = new FormData();
        formData.append("image", imageCoverPodcast); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imagePodcast = response.data.url; // Store the cover image URL
      }

      // Handle media upload based on whether it’s an audio or video file
      if (imagesVideo || imagesAudio) {
        const formData = new FormData();
        formData.append("image", imagesVideo || imagesAudio); // Append the media file

        // Upload media file and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        podcastMedia = response.data.url; // Store the media URL
      }

      // Prepare the payload based on the type of media
      const payload = {
        category: activeTab,
        podcastTitle: podcastTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        coverPhoto: imagePodcast,
        podcastType: eventFormat.value,
        podcastMedia: podcastMedia, // Use the media URL for video or audio
        podcastUserArray: userIdSelect,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset state after submission
      setPollTitle("");
      setPollCaption("");
      setPollOptions([]);
      setPollOpen(false);
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setImagesCoverPodcast("");
      setImageAudio("");
      setImageVideo("");
      getAll();
      setPodcastOpen(false);
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/get-all-user-list`
        );
        console.log(res, "fffsf");
        const userData = res.data.user.map((user) => ({
          name: `${user.firstName} ${user.lastName}`,
          userId: user.userId,
        }));
        setUserArray(userData);
        console.log(userData, "userData");
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    };

    fetchData();
  }, []);
  //event Data
  const [eventOpen, setEventOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventPhoto, setEventPhoto] = useState(null);
  const [eventPhotos, setEventPhotos] = useState([]);
  const [coverVideo, setCoverVideo] = useState(null);
  const [eventLink, setEventLink] = useState("");
  const [eventFormatEvent, setEventFormatChange] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [userSelects, setUserSelects] = useState([]);
  const [eventDesc, setEventDesc] = useState("");
  const handleEventForm = () => {
    setEventOpen((prevState) => !prevState);
  };
  const [selectedTimezones, setSelectedTimezones] = useState();

  const fileInputEventCoverRef = useRef();
  const fileInputEventPhotoRef = useRef();
  const fileInputEventCoverVideoRef = useRef();
  const handleDivEventVideoCoverClick = () => {
    fileInputEventCoverVideoRef.current?.click();
  };
  const handleDivEventCoverClick = () => {
    fileInputEventCoverRef.current?.click();
  };
  const handleEventInputChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setEventPhoto(file); // Store a single image (not in an array)
  };
  const removeEventImage = (index) => {
    setEventPhotos(eventPhotos.filter((_, i) => i !== index));
  };
  const handleDivEventPhotoClick = () => {
    fileInputEventPhotoRef.current.click();
  };
  const handleEventImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setEventPhotos((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };
  const handleEventInputVideoChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single video/audio file)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid video/audio formats (mp4, mp3)
    const validMediaTypes = ["video/mp4"];
    if (!validMediaTypes.includes(file.type)) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Check if the file size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      toast.error("File size exceeds 12 MB. Please select a smaller file.");
      return;
    }

    // Update the state with the selected image
    setCoverVideo(file); // Store a single image (not in an array)
  };
  const optionFormatEvent = [
    { label: "Online", value: "Audio" },
    { label: "Video", value: "Video" },
  ];
  const handleFormatEventChange = (e) => {
    setEventFormatChange(e);
  };
  const userIdSelects = userSelects.map((user) => ({
    name: user.name,
    userId: user.userId,
  }));
  const handleEventSubmit = async () => {
    setIsLoading(true);
    try {
      let imagePodcast = null; // Store the uploaded cover image URL
      let eventPodcast = null; // Store the uploaded cover image URL

      // Handle cover image upload if there's a single cover image
      if (eventPhoto) {
        const formData = new FormData();
        formData.append("image", eventPhoto); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imagePodcast = response.data.url; // Store the cover image URL
      }
      if (coverVideo) {
        const formData = new FormData();
        formData.append("image", coverVideo); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        eventPodcast = response.data.url; // Store the cover image URL
      }
      // Handle media upload based on whether it’s an audio or video file
      const bookFileUpload = [];

      // Handle image uploads if there are any images
      if (eventPhotos && eventPhotos.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          eventPhotos.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookFileUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Prepare the payload based on the type of media
      const payload = {
        category: activeTab,
        podcastTitle: eventTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        coverPhoto: imagePodcast,
        eventSpeakerArrays: userIdSelects,
        eventMediaPhoto: bookFileUpload,
        eventFormat: eventFormatEvent.value,
        eventLink: eventLink,
        eventStartDate: startDate,
        eventEndDate: endDate,
        eventTimeZone: selectedTimezones,
        eventDesc: eventDesc,
        eventCoverVideo: eventPodcast,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset state after submission
      setPollTitle("");
      setPollCaption("");
      setPollOptions([]);
      setPollOpen(false);
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setImagesCoverPodcast("");
      setImageAudio("");
      setImageVideo("");
      getAll();
      setEventOpen(false);
      setEventDesc("");
      setEventTitle("");
      setEventPhotos([]);
      setEventPhoto("");
      setStartDate("");
      setEndDate("");
      setSelectedTimezones("");
      setCoverVideo("");
      setEventLink("");
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  ///snippet

  const [snippetOpen, setSnippetOpen] = useState(false);
  const [snippetTitle, setSnippetTitle] = useState();
  const [videoCoverReel, setVideoCoverReel] = useState();
  const [thumbnailCover, setThumbnailCover] = useState();
  const fileInputrefVideo = useRef();
  const fileInputrefThumbnail = useRef();
  const [openTrim, setOpenTrim] = useState(false);
  const [trimmedVideo, setTrimmedVideo] = useState(null);
  const handleDivVideoSnippet = () => {
    fileInputrefVideo.current?.click();
  };
  const handleDivThumbnailSnippet = () => {
    fileInputrefThumbnail.current?.click();
  };
  const handleSnippetOpen = () => {
    setSnippetOpen((prevState) => !prevState);
  };
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setThumbnailCover(file); // Store a single image (not in an array)
  };
  const handleEventInputSnippetChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single video/audio file)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid video/audio formats (mp4, mp3)
    const validMediaTypes = ["video/mp4"];
    if (!validMediaTypes.includes(file.type)) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Check if the file size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      toast.error("File size exceeds 12 MB. Please select a smaller file.");
      return;
    }

    // Update the state with the selected image
    setVideoCoverReel(file); // Store a single image (not in an array)
    // setInterval(() => {
    //   setOpenTrim(true);
    // }, 2000);
  };
  const handleSnippetSubmit = async () => {
    setIsLoading(true);
    try {
      let imagePodcast = null; // Store the uploaded cover image URL
      let eventPodcast = null; // Store the uploaded cover image URL

      // Handle cover image upload if there's a single cover image
      if (videoCoverReel) {
        const formData = new FormData();
        formData.append("image", videoCoverReel); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imagePodcast = response.data.url; // Store the cover image URL
      }
      if (thumbnailCover) {
        const formData = new FormData();
        formData.append("image", thumbnailCover); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        eventPodcast = response.data.url; // Store the cover image URL
      }
      // Handle media upload based on whether it’s an audio or video file

      // Prepare the payload based on the type of media
      const payload = {
        category: activeTab,
        snippetTitle: snippetTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle ? formattedDates : formattedDates,
          timezone: selectedTimezone,
        },
        coverPhoto: eventPodcast,
        eventCoverSnippetVideo: imagePodcast,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset state after submission

      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images
      setOpenToggle(false);

      getAll();

      setSelectedTimezones("");
      setCoverVideo("");
      setEventLink("");
      setSnippetTitle("");
      setShortDesc("");
      setLongDesc("");
      setThumbnailCover("");
      setVideoCoverReel("");
      setSnippetOpen(false);
      setOpenSchedulePost(false)
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleTrimComplete = (trimmedBlob) => {
    setTrimmedVideo(trimmedBlob);
    setOpenTrim(false);
  };
  ///cousre
  const [openCourse, setOpenCourse] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const handleCourseForm = () => {
    setOpenCourse((prevState) => !prevState);
  };
  const [chapters, setChapters] = useState([]);
  const fileInputRefs = useRef({
    photo: useRef(null),
    video: useRef(null),
    ebook: useRef(null),
  });
  const handleSectionChange = (chapterIndex, sectionIndex, value) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections[sectionIndex].title = value;
    setChapters(updatedChapters);
  };
  // Add a new chapter
  const handleAddChapter = () => {
    setChapters([...chapters, { title: "", sections: [] }]);
  };

  // Handle chapter title change
  const handleChapterChange = (index, field, value) => {
    const updatedChapters = [...chapters];
    updatedChapters[index][field] = value;
    setChapters(updatedChapters);
  };

  // Remove chapter
  const handleRemoveChapter = (index) => {
    const updatedChapters = chapters.filter((_, i) => i !== index);
    setChapters(updatedChapters);
  };

  // Add a new section to a chapter
  const handleAddSection = (chapterIndex) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections.push({
      title: "",
      contentItems: [], // Initialize empty contentItems
    });
    setChapters(updatedChapters);
  };

  // Remove a section from a chapter
  const handleRemoveSection = (chapterIndex, sectionIndex) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections = updatedChapters[
      chapterIndex
    ].sections.filter((_, i) => i !== sectionIndex);
    setChapters(updatedChapters);
  };

  // Add a new content item to a section
  const handleAddContentItem = (chapterIndex, sectionIndex, contentType) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections[sectionIndex].contentItems.push({
      type: contentType,
      value: "", // Initialize with an empty value
    });
    setChapters(updatedChapters);
  };

  // Handle content item change (either text or file URL)
  const handleContentItemChange = (
    chapterIndex,
    sectionIndex,
    contentIndex,
    value
  ) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections[sectionIndex].contentItems[
      contentIndex
    ].value = value;
    setChapters(updatedChapters);
  };

  // Remove a content item from a section
  const handleRemoveContentItem = (
    chapterIndex,
    sectionIndex,
    contentIndex
  ) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].sections[sectionIndex].contentItems.splice(
      contentIndex,
      1
    );
    setChapters(updatedChapters);
  };

  // Handle file upload (e.g., photo, video, ebook)
// Handle File Upload for Various Content Types
const handleFileUpload = (chapterIndex, sectionIndex, contentIndex, event) => {
  const file = event.target.files[0]; // Get the first file selected
  if (!file) return; // Exit if no file is selected

  const contentItem =
    chapters[chapterIndex].sections[sectionIndex].contentItems[contentIndex];

  // Check file type and size based on content type (photo, video, ebook)
  const contentType = contentItem.type;

  let isValid = false;
  let errorMessage = "";
  let maxFileSize = 0; // In bytes

  switch (contentType) {
    case "photo":
      // Validate photo (image types)
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        errorMessage = "Only JPEG, PNG, and GIF formats are allowed for photos.";
        isValid = false;
      } else {
        isValid = true;
        maxFileSize = 5 * 1024 * 1024; // 5 MB limit for images
      }
      break;

    case "video":
      // Validate video (MP4 format)
      const validVideoTypes = ["video/mp4"];
      if (!validVideoTypes.includes(file.type)) {
        errorMessage = "Only MP4 format is allowed for videos.";
        isValid = false;
      } else {
        isValid = true;
        maxFileSize = 12 * 1024 * 1024; // 12 MB limit for videos
      }
      break;

    case "ebook":
      // Validate ebook (PDF format)
      const validEbookTypes = ["application/pdf"];
      if (!validEbookTypes.includes(file.type)) {
        errorMessage = "Only PDF format is allowed for ebooks.";
        isValid = false;
      } else {
        isValid = true;
        maxFileSize = 15 * 1024 * 1024; // 15 MB limit for ebooks
      }
      break;

    default:
      isValid = false;
      errorMessage = "Unsupported file type.";
  }

  // Check if the file size exceeds the limit
  if (isValid && file.size > maxFileSize) {
    errorMessage = `File size exceeds the ${maxFileSize / (1024 * 1024)} MB limit.`;
    isValid = false;
  }

  if (isValid) {
    // Update the contentItem with the selected file
    contentItem.value = file.name; // You can also store the file itself if needed

    // Trigger the state update
    const updatedChapters = [...chapters];
    setChapters(updatedChapters);
  } else {
    // Display error message if the file is invalid
    toast.error(errorMessage);
  }
};


  const triggerFileInput = (contentType) => {
    fileInputRefs.current[contentType].current.click();
  };
  const handleCourseSubmit = async () => {
    setIsLoading(true); // Set loading state while submitting
  
    try {
      let eventPodcast = null; // Store the uploaded cover image URL
      let uploadedFiles = {
        photo: [],
        video: [],
        ebook: [],
      };
  
      // Handle cover image upload if there's a single cover image
      if (thumbnailCover) {
        const formData = new FormData();
        formData.append("image", thumbnailCover); // Append the cover image file
  
        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        eventPodcast = response.data.url; // Store the cover image URL
      }
  
      // Function to upload a photo and return the URL
      const uploadPhoto = async (photo) => {
        const formData = new FormData();
        formData.append("image", photo); // Append the photo file
  
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data.url;
      };
  
      // Function to upload a video and return the URL
      const uploadVideo = async (video) => {
        const formData = new FormData();
        formData.append("image", video); // Append the video file
  
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data.url;
      };
  
      // Function to upload an ebook and return the URL
      const uploadEbook = async (ebook) => {
        const formData = new FormData();
        formData.append("image", ebook); // Append the ebook file
  
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data.url;
      };
  
      // Upload each file type if there are files
      if (uploadedFiles.photo.length > 0) {
        for (let i = 0; i < uploadedFiles.photo.length; i++) {
          uploadedFiles.photo[i] = await uploadPhoto(uploadedFiles.photo[i]);
        }
      }
  
      if (uploadedFiles.video.length > 0) {
        for (let i = 0; i < uploadedFiles.video.length; i++) {
          uploadedFiles.video[i] = await uploadVideo(uploadedFiles.video[i]);
        }
      }
  
      if (uploadedFiles.ebook.length > 0) {
        for (let i = 0; i < uploadedFiles.ebook.length; i++) {
          uploadedFiles.ebook[i] = await uploadEbook(uploadedFiles.ebook[i]);
        }
      }
  
      // Now, let's map through the chapters and update contentItems with the uploaded files
      const formattedChapters = chapters.map((chapter) => ({
        title: chapter.title,
        sections: chapter.sections.map((section) => ({
          title: section.title,
          contentItems: section.contentItems.map((item) => {
            // Check file type and assign uploaded URLs
            if (item.type === "photo" && uploadedFiles.photo.length > 0) {
              const uploadedPhoto = uploadedFiles.photo.shift(); // Get the uploaded photo URL
              return {
                type: item.type,
                value: uploadedPhoto, // Use the uploaded photo URL
              };
            } else if (
              item.type === "video" &&
              uploadedFiles.video.length > 0
            ) {
              const uploadedVideo = uploadedFiles.video.shift(); // Get the uploaded video URL
              return {
                type: item.type,
                value: uploadedVideo, // Use the uploaded video URL
              };
            } else if (
              item.type === "ebook" &&
              uploadedFiles.ebook.length > 0
            ) {
              const uploadedEbook = uploadedFiles.ebook.shift(); // Get the uploaded ebook URL
              return {
                type: item.type,
                value: uploadedEbook, // Use the uploaded ebook URL
              };
            } else if (item.type === "text") {
              // For text articles, directly set the text content in the value
              return {
                type: item.type,
                value: item.value || "Default text article content", // Set text content
              };
            } else if (item.type === "quiz") {
              // For quizzes, directly set the quiz question, options, and correct answers in the value
              return {
                type: item.type,
                value: item.value,
              };
            } else if (item.type === "article") {
              return {
                type: item.type,
                value: item.value,
              };
            }
            return item; // If no upload or no type match, just return the item
          }),
        })),
      }));
  
      // Prepare the payload for the API request
      const payload = {
        category: activeTab,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle ? formattedDates : formattedDates,
          timezone: selectedTimezone,
        },
        courseTitle: courseTitle,
        chapters: formattedChapters,
        coverPhoto: eventPodcast, // Include the cover image URL
  
        shortDesc: shortDesc,
        longDesc: longDesc,
      };
  
      // Send the request to create the course
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );
  
      // Reset state after submission
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setCourseTitle(""); // Reset course title
      setShortDesc("");
      setLongDesc("");
      setChapters([]); // Clear chapters
      setOpenToggle(false); // Close the form
      getAll(); // Call the function to refresh the data
      setOpenSchedulePost(false)
  
      setSelectedTimezones("");
      setCoverVideo("");
      setSnippetTitle("");
      setThumbnailCover(""); // Reset thumbnail cover
      setVideoCoverReel(""); // Reset video cover reel
      setOpenCourse(false);
      setCourseTitle("");
      setChapters([]);
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };
  
  
  
  return (
    <>
      <ToastContainer />
      <div className="cardContainer">
        <div className="contentDiv">
          <img
            src={input?.avatar ? input?.avatar : companyLogo}
            alt="Profile"
            className="profileImage"
          />
          <div className="textContent">
            {inputValue?.length > 0 ? (
              <textarea
                value={inputValue}
                placeholder="What's on your mind?"
                className="inputField"
                onChange={handleInputChange}
                rows={3}
              />
            ) : (
              <input
                type="text"
                placeholder="What's on your mind?"
                className="inputField"
                value={inputValue}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
        <div className="character">{inputValue?.length}/3000 Characters</div>
        <div className="optionsContainer">
          {activeTab === "Photo" || activeTab === "Video" ? (
            <>
              {" "}
              <div
                className="option"
                onClick={handlePhotoOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FaPhotoVideo size={20} />
                <div>Photo</div>
              </div>
            </>
          ) : (
            <>
              <div className="option" onClick={handleDivClick}>
                <FaPhotoVideo size={20} />
                Photo
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the input
                onChange={handleImageChange}
              />
            </>
          )}
          {activeTab === "Video" || activeTab === "Photo" ? (
            <>
              <div
                className="option"
                onClick={handleVideoOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaVideo size={20} />
                Video
              </div>
            </>
          ) : (
            <>
              <div
                className="option"
                onClick={handleDivVideoClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaVideo size={20} />
                Video
              </div>
              <input
                type="file"
                accept="video/*"
                multiple
                ref={fileInputVideoRef}
                style={{ display: "none" }} // Hide the input
                onChange={handleVideoChange}
              />
            </>
          )}

          <div
            className="option"
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            <MdWork size={20} />
            Job
          </div>
          <div
            className="option"
            onClick={handleMore}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            {openMore ? (
              <>
                <IoMdArrowDropdown size={20} />
                <span style={{ marginLeft: "5px" }}>Less</span>
              </>
            ) : (
              <>
                <RxHamburgerMenu size={20} />
                <span style={{ marginLeft: "5px" }}>More</span>
              </>
            )}
          </div>
        </div>
        {openMore && (
          <>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleArticleForm}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <RiArticleLine size={20} />
                Article
              </div>

              <div
                className="option"
                onClick={handleCourseForm}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaBookOpen size={20} />
                Course
              </div>

              <div
                className="option"
                onClick={handleEbookOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <TbBookFilled size={20} />E Book
              </div>
              <div
                className="option"
                onClick={handleSnippetOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <AiOutlineSnippets size={20} />
                Snippet
              </div>
            </div>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleEventForm}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <MdEvent size={20} />
                Event
              </div>

              <div
                className="option"
                onClick={() => setPollOpen(true)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPoll size={20} />
                Polls
              </div>

              <div
                className="option"
                onClick={() => setPodcastOpen((prevstate) => !prevstate)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPodcast size={20} />
                Podcast
              </div>
              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <CiStreamOn size={20} />
                Live
              </div>
            </div>
          </>
        )}
        {activeTab === "Photo" || activeTab === "Article" ? (
          ""
        ) : (
          <>
            {" "}
            {images.length > 0 && (
              <div>
                <h2>Preview:</h2>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: "5px" }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          borderRadius: "4px",
                        }}
                      />
                      <button
                        onClick={() => removeImage(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 255, 255, 0.7)",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "2px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {activeTab === "Video" ? (
          ""
        ) : (
          <>
            {videos.length > 0 && (
              <div>
                <h2>Preview:</h2>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {videos.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: "5px" }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          borderRadius: "4px",
                        }}
                      />
                      <button
                        onClick={() => removeVideo(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 255, 255, 0.7)",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "2px",
                        }}
                      >
                        ✖️
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        <div className="buttonDiv">
          <button className="button2" onClick={handleToggle}>
            Next
          </button>
        </div>
      </div>
      <Dialog
        open={openToggle}
        PaperProps={{ className: "dialogPaper" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <div className="toggleContainer">
            <span> Private</span>
            <div className="toggle-wrap">
              <Toggle
                onChange={handlePrivacyChange}
                checked={privacy === "PUBLIC"}
              />
            </div>
            <span> Public</span>
          </div>
          <div className="dialogButtonContainer">
            <button className="button2" onClick={() => setOpenToggle(false)}>
              Back
            </button>
            <button
              className="button2"
              onClick={() => setOpenSchedulePost(true)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSchedulePost}
        // PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="md"
      >
        <div style={{ minHeight: showDatePicker ? "700px" : "200px" }}>
          <h4>Schedule Post</h4>
          <div className="toggleContainer">
            <div>Post Immediately</div>
            <div className="toggle-wrap">
              <Toggle onChange={handleToggleOne} checked={scheduleToggle} />
            </div>
            <div>Schedule Post</div>
          </div>
          {scheduleToggle && (
            <div>
              <div className="scheduleOptions">
                <div className="scheduleTime">
                  <label>Date and Time</label>
                  <div
                    className={
                      showDatePicker ? "schedule-foot" : "schedule-footer"
                    }
                  >
                    <button
                      className="button5"
                      style={{ width: "160px" }}
                      onClick={() => setShowDatePicker(true)}
                    >
                      <img src={Calender} alt="img" style={{ width: "30px" }} />
                      Schedule
                    </button>
                  </div>
                  {showDatePicker && (
                    <div className="poll-datepicker">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm aa"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        open={showDatePicker}
                        onCalendarOpen={() => setShowDatePicker(true)}
                        onCalendarClose={() => setShowDatePicker(false)}
                        renderCustomHeader={renderCustomHeader}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="scheduleOptions">
                <div className="scheduleTime">
                  <label>Time Zone</label>
                  <select
                    value={selectedTimezone}
                    onChange={(e) => setSelectedTimezone(e.target.value)}
                    className="timezoneDropdown"
                  >
                    {timezones.map((tz) => (
                      <option key={tz.name} value={tz.name}>
                        {tz.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
            </div>
          )}
          <div className="dialogButtonContainer scheduleButtons">
            {activeTab === "Article" ? (
              <button
                className="button2"
                onClick={handleArticleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Ebook" ? (
              <button
                className="social-post-btn"
                onClick={handleEbookSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Polls" ? (
              <button
                className="button2"
                onClick={handlePoleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Podcast" ? (
              <button
                className="button2"
                onClick={handlePodCastSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Video" ? (
              <button
                className="button2"
                onClick={handlevideoSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Event" ? (
              <button
                className="button2"
                onClick={handleEventSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Snippet" ? (
              <button
                className="button2"
                onClick={handleSnippetSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : activeTab === "Course" ? (
              <button
                className="button2"
                onClick={handleCourseSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            ) : (
              <button
                className="button2"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Posting...</span> // Show loading text
                ) : (
                  <span>Post</span> // Default button text
                )}
              </button>
            )}
            <button
              className="button2 back-btn"
              onClick={() => setOpenSchedulePost(false)}
            >
              Back
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openPhotoForm}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img
            src={CloseIcon}
            onClick={() => setOpenPhotoForm(false)}
            alt="ij"
          />
        </div>
        <DialogContent style={{ margin: "15px", overflowY: "hidden" }}>
          <PhotoFormMedia
            handleToggle={handleToggle}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            images={images}
            fileInputRef={fileInputRef}
            handleDivClick={handleDivClick}
            handleImageChange={handleImageChange}
            removeImage={removeImage}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openVideoForm}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="lg"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img
            src={CloseIcon}
            onClick={() => setOpenVideoForm(false)}
            alt="ij"
          />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <VideoFormMedia
            handleToggle={handleToggle}
            setShortDesc={setShortDesc}
            shortDesc={shortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            videos={videos}
            fileInputVideoRef={fileInputVideoRef}
            handleDivVideoClick={handleDivVideoClick}
            handleVideoChange={handleVideoChange}
            removeVideo={removeVideo}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={articleOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setArticleOpen(false)} alt="ij" />
        </div>
        <DialogContent>
          <ArticleFormMedia
            articleTitle={articleTitle}
            articleDescription={articleDescription}
            setArticleTitle={setArticleTitle}
            setArticleDescription={setArticleDescription}
            getArticleTitleText={getArticleTitleText}
            handleDivClick={handleDivClick}
            handleCoverChange={handleCoverChange}
            handleDivCoverClick={handleDivCoverClick}
            handleImageChange={handleImageChange}
            handleToggle={handleToggle}
            images={images}
            removeImage={removeImage}
            fileInputRef={fileInputRef}
            fileInputCoverRef={fileInputCoverRef}
            imagesCover={imagesCover}
            shortDesc={shortDesc}
            longDesc={longDesc}
            setShortDesc={setShortDesc}
            setLongDesc={setLongDesc}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={ebookOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        minWidth="md"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setEbookOpen(false)} alt="ij" />
        </div>
        <DialogContent>
          <EbookMediaForm
            handleToggle={handleToggle}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            bookTitle={bookTitle}
            setBookTitle={setBookTitle}
            handleDivBookFileClick={handleDivBookFileClick}
            fileInputBookFileRef={fileInputBookFileRef}
            handleEbookFileChange={handleEbookFileChange}
            fileInputBookVideoRef={fileInputBookVideoRef}
            handleEbookVideoChange={handleEbookVideoChange}
            handleCoverChange={handleBookCoverChange}
            bookVideo={bookVideo}
            handleDivBookCoverClick={handleDivBookCoverClick}
            fileInputBookCoverRef={fileInputBookCoverRef}
            file={file}
            setFile={setFile}
            bookCover={bookCover}
            setBookCover={setBookCover}
            removeBookFile={removeBookFile}
            handleDivBookPhotoClick={handleDivBookPhotoClick}
            fileInputBookPhotoRef={fileInputBookPhotoRef}
            handleEbookImageChange={handleEbookImageChange}
            bookPhoto={bookPhoto}
            removeBookVideo={removeBookVideo}
            removeBookImage={removeBookImage}
            handleDivBookVideoClick={handleDivBookVideoClick}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={pollOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setPollOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <PollMediaForm
            handleToggle={handleToggle}
            pollTitle={pollTitle}
            setPollTitle={setPollTitle}
            pollOption={pollOptions}
            setPollOption={setPollOptions}
            pollCaption={pollCaption}
            setPollCaption={setPollCaption}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={podcastOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setPodcastOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <PodCastForm
            handleToggle={handleToggle}
            podcastTitle={podcastTitle}
            setPodcastTitle={setPodcastTitle}
            handlePodcastTitle={handlePodcastTitle}
            optionFormat={optionFormat}
            handleFormatChange={handleFormatChange}
            eventFormat={eventFormat}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            handleDivPodcastClick={handleDivPodcastClick}
            handleVideoPodcast={handleVideoPodcast}
            fileInputPodcastRef={fileInputPodcastRef}
            imagesVideo={imagesVideo}
            handleCoverPodcastChange={handleCoverPodcastChange}
            handleDivPodcastImageClick={handleDivPodcastImageClick}
            imageCoverPodcast={imageCoverPodcast}
            fileInputPodcasImagetRef={fileInputPodcasImagetRef}
            handleDivPodcastAudioClick={handleDivPodcastAudioClick}
            handleAudioPodcast={handleAudioPodcast}
            fileInputPodcastAudioRef={fileInputPodcastAudioRef}
            imagesAudio={imagesAudio}
            userArray={userArray}
            setUserSelect={setUserSelect}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={eventOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setEventOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <EventFormMedia
            handleToggle={handleToggle}
            eventTitle={eventTitle}
            setEventTitle={setEventTitle}
            userArray={userArray}
            setUserSelect={setUserSelect}
            fileInputEventCoverRef={fileInputEventCoverRef}
            handleDivEventCoverClick={handleDivEventCoverClick}
            handleInputEventChange={handleEventInputChange}
            eventDesc={eventDesc}
            setEventDesc={setEventDesc}
            removeEventImage={removeEventImage}
            handleDivEventPhotoClick={handleDivEventPhotoClick}
            fileInputEventPhotoRef={fileInputEventPhotoRef}
            handleEventImageChange={handleEventImageChange}
            eventPhotos={eventPhotos}
            handleDivEventCoverVideoClick={handleDivEventVideoCoverClick}
            fileInputEventCoverVideoRef={fileInputEventCoverVideoRef}
            handleInputEventCoverChange={handleEventInputVideoChange}
            coverVideo={coverVideo}
            eventPhoto={eventPhoto}
            optionFormat={optionFormatEvent}
            handleFormatChange={handleFormatEventChange}
            eventFormat={eventFormatEvent}
            eventLink={eventLink}
            setEventLink={setEventLink}
            selectedTimezone={selectedTimezones}
            setSelectedTimezone={setSelectedTimezones}
            timezones={timezones}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            userArrays={userArray}
            setUserSelects={setUserSelects}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={snippetOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setSnippetOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <SnippetFormMedia
            handleToggle={handleToggle}
            snippetTitle={snippetTitle}
            setSnippetTitle={setSnippetTitle}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            videoCoverReel={trimmedVideo}
            thumbnailCover={thumbnailCover}
            fileInputrefVideo={fileInputrefVideo}
            fileInputrefThumbnail={fileInputrefThumbnail}
            handleDivVideoSnippet={handleDivVideoSnippet}
            handleDivThumbnailSnippet={handleDivThumbnailSnippet}
            handleThumbnailChange={handleThumbnailChange}
            handleEventInputSnippetChange={handleEventInputSnippetChange}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTrim}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setOpenTrim(false)} alt="ij" />
        </div>
        <DialogContent>
          <VideoDialogTrim
            open={openTrim}
            onClose={() => setOpenTrim(false)}
            videoCoverReel={videoCoverReel}
            onTrimComplete={handleTrimComplete}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCourse}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setOpenCourse(false)} alt="ij" />
        </div>
        <DialogContent>
          <CourseMediaForm
            courseTitle={courseTitle}
            setCourseTitle={setCourseTitle}
            handleDivThumbnailSnippet={handleDivThumbnailSnippet}
            fileInputrefThumbnail={fileInputrefThumbnail}
            handleThumbnailChange={handleThumbnailChange}
            thumbnailCover={thumbnailCover}
            setShortDesc={setShortDesc}
            setLongDesc={setLongDesc}
            shortDesc={shortDesc}
            longDesc={longDesc}
            handleToggle={handleToggle}
            triggerFileInput={triggerFileInput}
            chapters={chapters}
            handleAddChapter={handleAddChapter}
            handleChapterChange={handleChapterChange}
            handleRemoveChapter={handleRemoveChapter}
            handleAddSection={handleAddSection}
            handleRemoveSection={handleRemoveSection}
            handleAddContentItem={handleAddContentItem}
            handleContentItemChange={handleContentItemChange}
            handleRemoveContentItem={handleRemoveContentItem}
            fileInputRefs={fileInputRefs}
            handleFileUpload={handleFileUpload}
            handleSectionChange={handleSectionChange}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextCard;

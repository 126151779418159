/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import "../Jobs/jobs.css";
import "./interview.css";
import "../Aptitude/aptitude.css";
import companyLogo from "../../images/company.png";
import iClock from "../../images/Alarm-icon.svg";
import interviewLogo from "../../images/interview.png";
import iCalendar from "../../images/icons/calendar.svg";
import iClockSimple from "../../images/icons/clock-simple.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import CustomHeaderInterview from "./customHeaderInterview";
import { FaPlayCircle } from "react-icons/fa";
import Slider from "react-slick";
import noData from "../../images/NoData.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import iBookmark from "../../images/icons/bookmark.svg";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { CiLinkedin } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import Readmore from "./readMores";
import { Image, Shimmer } from "react-shimmer";
import ShimmerLoading from "./shimmerloading";
const ImageContainer = styled.div`
  position: relative;
`;
const ImageWrapper = styled.div`
  position: relative;
  height: 168px;
  bottom: 2px;
  left: 2px;
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 140px;
    height: 168px;
  }
`;

const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Time = styled.div`
  margin-left: auto;
  font-size: 14px;
  margin-top: 5px;
`;
const BookedInterview = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [datainter, setDatainter] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueOne, setValueOne] = useState();
  const [valueTwo, setValueTwo] = useState();
  const cardClick = (path) => {
    return history.push("/portal/my-calendar");
  };
  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };

  const [pagination, setPagination] = useState(1);
  const [loading, setLoading] = useState(true);
  const [exhausted, setExhausted] = useState(true);
  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  const [calendar, setCalendar] = useState([]);
 
  const getAllBookedInterview = async () => {  
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-booked-interview?p=${pagination}`;  
  
    try {  
      const response = await axios.get(url);  
  
      if (response.data) {  
        const { book, pageCount, interview } = response.data;  
  
        // Check if there are no booked interviews  
        if (book.length === 0) {  
          setLoading(false);  
          setExhausted(false);  
          return; // Exit early if no books  
        }  
  
        setPagination(pageCount);  
  
        // Filter out unique booked jobs  
        const uniqueBook = book.filter(  
          (job) => !data.some((existingBook) => existingBook._id === job._id)  
        );  
  
        // Update loading and exhausted state based on unique books  
        if (uniqueBook.length < 4) {  
          setLoading(false);  
          setExhausted(false);  
        }  
  
        // Update interview data  
        const updatedInterviews = { ...datainter };  
        interview.forEach((interviewItem) => {  
          updatedInterviews[interviewItem._id] = interviewItem;  
        });  
        setDatainter(updatedInterviews);  
  
        // Update booked jobs data  
        setData((current) => {  
          if (current) {  
            return [...current, ...uniqueBook];  
          } else {  
            return uniqueBook; // Return uniqueBook if current is undefined  
          }  
        });  
  
        // Check if we are exhausted based on uniqueBook length  
        if (uniqueBook.length < 5) {  
          setExhausted(false);  
        }  
  
        setLoading(false);  
      }  
    } catch (error) {  
      console.error("Error fetching booked interviews:", error);  
      setLoading(false); // Ensure loading state is updated on error  
      // Optionally, handle error state (e.g., show a notification)  
    }  
  };
  const convertdata = (dateString) => {
    var date = new Date(dateString);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var day = date.getUTCDate() + 1;
    var month = months[date.getUTCMonth()];
    var year = date.getUTCFullYear();
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    var ordinalSuffix = getOrdinalSuffix(day);
    var formattedDate = day + ordinalSuffix + " " + month + " " + year;
    return formattedDate;
  };
  const [social, setOpenSocial] = useState(false);
  const [onPopupData, setPopupData] = useState();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  function formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day}${ordinalSuffix(day)} ${monthNames[monthIndex]} ${year}`;
  }

  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours %= 12;
    hours = hours || 12; // If hours is 0, set it to 12

    // Pad single digit minutes with leading zero
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes + " " + ampm;
  }

  // eslint-disable-next-line no-unused-vars
  function isPastThirtyMinutes(a) {
    const currentTime = new Date();
    const testTime = new Date(a.date);
    testTime.setHours(a.time.split(":")[0]);
    testTime.setMinutes(a.time.split(":")[1]);

    return currentTime.getTime() > testTime.getTime() + 30 * 60000;
  }

  function isPastFormattedDateTime(a) {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    const formattedTime = formatTime(currentDate);

    return formattedDate === convertdata(a.date) && formattedTime >= a.time;
  }
  useEffect(() => {
    getAllBookedInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [userId, setUser] = useState({});
 
  const getUser = async () => {  
    try {  
      const response = await axios.get(`${process.env.PUBLIC_URL}/xakal/getuser`);  
  
      // Check if response is valid and has data  
      if (response.data) {  
        setUser(response.data);  
      }  
    } catch (error) {  
      console.error("Error fetching user data:", error);  
      // Optionally, handle error state (e.g., show an error notification)  
    }  
  };  
  useEffect(() => {
    getUser();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [savedInterviews, setSavedInterviews] = useState([]);

  const updateToSavedInterview = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/update-saved-book-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleWhatsAppShare = () => {
    const jobName = onPopupData.interviewTitle;
    const jobId = onPopupData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData._id;
    const jobTitle = onPopupData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.interviewTitle;
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };
  const openPopup = (data) => {
    setPopupData(data);
    setOpenSocial(true);
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
      const intervalId = setInterval(calculateTimeAgo, 60000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      };
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-booked/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const formatDates = (date) => {
    const currentDate = new Date(date).toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    return currentDate;
  };
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const currentDatesss = new Date(); // Get current date/time

  // Extract hours and minutes
  let hours = currentDatesss.getHours();
  let minutes = currentDatesss.getMinutes();

  // Determine AM/PM and adjust hours
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours from 24-hour to 12-hour format

  // Format the time as "hh:mm AM/PM"
  const formattedTime = `${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;
  console.log(formattedTime, "formattedTime");
  const adjustTimeAndFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0; // Midnight
    }

    let adjustedMinutes = minutes + 30;
    if (adjustedMinutes >= 60) {
      adjustedHours += 1;
      adjustedMinutes -= 60;
    }

    const adjustedPeriod = adjustedHours >= 12 ? "PM" : "AM";
    const formattedHours = adjustedHours % 12 || 12; // Convert hours from 24-hour to 12-hour format

    const formattedTime = `${formattedHours}:${
      adjustedMinutes < 10 ? "0" : ""
    }${adjustedMinutes} ${adjustedPeriod}`;

    return formattedTime;
  };
console.log(valueOne)
  return (
    <div>
      <div className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader hTitle="Interviews" />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader title={"Interviews"} />
        {/* End of desktop Header  */}
      </div>
      {/* {searchInputVal?.length == 0 ? ( */}
      <>
        <CustomHeaderInterview />
        {loading ? (
          <div style={{ top: "160px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : data && data?.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={getAllBookedInterview}
            hasMore={exhausted}
            loader={
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "50px",
                }}
              >
                <Spinner />
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div
              className="job-card-container"
              style={{ paddingBottom: "30px" }}
            >
              {data &&
                data.map((a, index) => {
                  let i = datainter[a["serviceId"]];
                  const saved = a.savedBy.find(
                    (fav) => fav.userId === userId.userId
                  );
                  const savedApplied =
                    saved && saved.status === "saved" ? true : false;
                  const dates = formatDates(a?.date);
                  const adjustTime = adjustTimeAndFormat(a?.time);
                  const isDisplay =
                    currentDate >= dates &&
                    adjustTime >= adjustTimeAndFormat(a?.time);
                  return (
                    <>
                      {i !== undefined ? (
                        <div
                          className="job-card-wrapper job-card-wrappers int-row int-rows"
                          onClick={cardClick}
                        >
                          <div className="int-container">
                            <div className="int-content-container">
                              <div className="int-title-row int-titles">
                                <div className="int-creator">
                                  <div>
                                    {i?.createdBy?.logo ? (
                                      <img
                                        src={i?.createdBy?.logo}
                                        alt="img"
                                        className="int-creator-img img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={companyLogo}
                                        alt="img"
                                        className="int-creator-img img-fluid"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="int-tit-save">
                                  <div className="int-details">
                                    <h5 className="title" onClick={cardClick}>
                                      <div className="profile-name">
                                        <span className="pro-name">
                                          {" "}
                                          {i.interviewTitle?.substring(0, 20)}
                                          {i.interviewTitle?.length > 20
                                            ? "..."
                                            : ""}
                                        </span>
                                        {i.interviewTitle?.length > 20 ? (
                                          <p className="prof-name">
                                            {i.interviewTitle}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </h5>
                                    <div>
                                      <span className="int-cre-lite">by</span>
                                      <span
                                        className="int-cre-title"
                                        onClick={(e) => {
                                          history.push(
                                            `/portal/other-profile/${i?.createdBy?.userId}`
                                          );
                                          e.stopPropagation();
                                        }}
                                      >
                                        {i?.createdBy?.firstName}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="ver-line"></div>
                                  <div className="b-slot">
                                    <div>
                                      {i?.inr ? (
                                        <p className="b-price">
                                          <i className="fa fa-rupee"></i>{" "}
                                          {new Intl.NumberFormat(
                                            "en-IN",
                                            {}
                                          ).format(i?.inr)}
                                        </p>
                                      ) : (
                                        <p className="b-price">Free</p>
                                      )}
                                    </div>
                                    <div className="text-center">
                                      <span className="timer">
                                        <span>{i?.duration}</span>
                                        <span>
                                          {" "}
                                          <img
                                            src={iClock}
                                            className="clock-icon"
                                            alt="icon"
                                          />
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {i?.designation.length ? (
                                <div className="prefer-wrap">
                                  <div className="prefer-wraps">
                                    <div className="job-preferences">
                                      Designations :
                                    </div>
                                    {i?.designation
                                      ?.slice(0, 3)
                                      .map((item, index) => (
                                        <div class="job-preference-pill">
                                          {item}
                                        </div>
                                      ))}
                                    {i.designation.length > 3 ? (
                                      <div class="job-preference-pill">
                                        +{i.designation.length - 3}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn job-btn w-100 bs-btn"
                                      onClick={(e) => {
                                        setValueOne(a);
                                        setOpenConfirm(true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Join Now
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>
          </InfiniteScroll>
        ) : (
          <img src={noData} className="nodata-center" alt="icon" />
        )}
      </>
      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <HiOutlineMail
                size={24}
                onClick={handleEmailShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <CiLinkedin
                size={24}
                onClick={handleLinkedInShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy
                  size={24}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <div className="modalHeader">
          <div className="mTitle">Disclaimer</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenConfirm(false);
            }}
          />
        </div>
        <DialogContent>
          <div className="modalBody" style={{ padding: "20px 20px 0 20px" }}>
            <p style={{ color: "#4F4953", fontweight: "normal" }}>
              We want to remind you that the interview session will be recorded
              for quality assurance purposes.{" "}
            </p>
            <p style={{ color: "#4F4953", fontweight: "normal" }}>
              We kindly request that you refrain from disclosing any personal
              details during the interview process.
            </p>
            {/* <p style={{color:"#4F4953",fontweight: "normal"}}>Wishing you the best of luck with your interview!</p> */}
          </div>
        </DialogContent>
        <div className="modalFooter" style={{ padding: "10px" }}>
          <button
            className="button2"
            onClick={() =>
              history.push({
                pathname: `/portal/videoRoom/${valueOne?._id}/${valueOne.calendarEvents[0]?.roomName}`,
                state: {
                  main_id: valueOne.calendarEvents[0]?._id,
                  serviceId: valueOne?.serviceId,
                },
              })
            }
          >
            Okay, Proceed
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default BookedInterview;

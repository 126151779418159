import { useEffect, useRef, useState } from "react";
import "./message.css";
import companyLogo from "../../images/profile-image.svg";
import axios from "axios";
import { FaRegFilePdf, FaFileExcel, FaFilePowerpoint } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import InputGroup from "react-bootstrap/InputGroup";
import { LuSendHorizonal } from "react-icons/lu";
import { IoIosAttach } from "react-icons/io";
import { io } from "socket.io-client";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { GoSmiley } from "react-icons/go";
import { BiTrash } from "react-icons/bi";
import { FaReply } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "@mui/material";

const MessageChat = ({
  userId,
  messageChats,
  id,
  onCallMessage,
  otherProfile,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [mainMessage, setMainMessage] = useState([]);
  const [mainArrival, setMainArrival] = useState([]);
  const [text, setText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [isVisibleOpen, setIsVisibleOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [openImage, setOpenImage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [imageToOpen, setImageToOpen] = useState(null);
  const fileImage = [".pdf", ".doc", ".docx", ".ppt", ".xls", ".xlsx", ".pptx"];
  const fileText = [".png", ".jpg", ".jpeg", ".gif"];
  const socket = useRef();
  const textareaRef = useRef(null);
  const history = useHistory();

  const [timer, setTimer] = useState(null); // To store the timeout ID for clearing it on hover

  const handleMouseEnter = (messageId) => {
    if (timer) clearTimeout(timer); // Clear any previous timeout if it exists
    setHoveredMessageId(messageId);
  };
  
  const handleMouseLeave = () => {
    const newTimer = setTimeout(() => {
      setHoveredMessageId(null);
    }, 1000); // Delay of 500ms before hiding the message actions (you can adjust this duration)
    setTimer(newTimer);
  };

  const getAllMessage = async (messageChats) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChats}`
      );
      setMainMessage(res.data.message);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
  }, [messageChats, messageChats?._id]);

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };
      calculateTimeAgo();
    }, [timestamp]);

    return <span className="mes-time">{timeAgo}</span>;
  };
  const [user, setUsers] = useState({});
  const friendIds = Array.from(new Set(mainMessage?.map((m) => m.sender)));
  const hasFetchedData = useRef(false);

  const getUserChat = async (friendIds) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-user-multiple`,
        { ids: friendIds }
      );
      const usersData = response.data.aboutDetails;
      const usersMap = usersData.reduce(
        (acc, user) => ({ ...acc, [user.userId]: user }),
        {}
      );
      setUsers(usersMap);
      hasFetchedData.current = true;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current && friendIds.length > 0) {
      getUserChat(friendIds);
    }
  }, [friendIds]);

  useEffect(() => {
    // Check if the socket is already initialized
    if (!socket.current) {
      socket.current = io(process.env.PUBLIC_URL);
      // socket.current = io("wss://test-staging.thexakal.com"); // Uncomment for different environment

      // Set up the event listeners
      socket.current.on("getMessage", (data) => {
        const timestamp = { createdAt: new Date() };
        setMainArrival({
          sender: data.senderId,
          text: data.text,
          image: data?.image,
          timestamps: timestamp,
        });
      });

      socket.current.on("deleteMessage", (deletedMessageId) => {
        setMainMessage((prevMessages) =>
          prevMessages.filter((message) => message._id !== deletedMessageId)
        );
      });

      socket.current.on("getUsers", (users) => {
        // You can handle users data here if needed
      });

      // Emit the "addUser" event with userId
      socket.current.emit("addUser", userId);
    }

    // Cleanup function to disconnect socket when the component unmounts
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [userId]); // The effect will run when the component mounts and whenever userId changes

  useEffect(() => {
    if (mainArrival && messageChats?.members?.includes(mainArrival.sender)) {
      setMainMessage((prev) => [...prev, mainArrival]);
    }
  }, [mainArrival, messageChats]);

  const handleReply = (message) => {
    setSelectedMessage(message);
    const replyText = `Reply to: ${message.text}\n`;
    setText(replyText);
    if (textareaRef.current) {
      textareaRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textareaRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleEmojiSelect = (emoji) => {
    const selectedEmoji = emoji.native;
    const cursorPosition = textareaRef.current.selectionStart;
    const value = text || "";
    const newValue =
      value.substring(0, cursorPosition) +
      selectedEmoji +
      value.substring(cursorPosition);
    setText(newValue);
  };

  const sendMessage = async () => {
    // Check if there's no text, file, or emojis selected
    if (!text.trim() && !selectedFile && selectedEmojis.length === 0) {
      return; // Do nothing if there's no message content (text, file, or emoji)
    }

    // Construct the message with emojis if any are selected
    let completeMessage =
      text + (selectedEmojis.length > 0 ? ` ${selectedEmojis.join(" ")}` : "");

    // If replying to a message, prepend it to the complete message
    if (selectedMessage) {
      const replyToMessage = `${selectedMessage.text}`;
      completeMessage = `${replyToMessage}\n${completeMessage}`;
    }

    // Define the payload for sending the message
    const payload = {
      conversationId: messageChats?._id,
      sender: userId,
      text: completeMessage, // Message text (with emojis if added)
    };

    // Find the receiver by excluding the current userId
    const receiverId = messageChats?.members?.find((m) => m !== userId);

    // Function to send the message with the optional file (image URL)
    const sendPayload = (fileUrl = "") => {
      const completePayload = {
        ...payload,
        text: payload.text || "", // Send the text or an empty string if none
        image: fileUrl, // Include the file URL if available
      };

      // Emit the message via socket for real-time updates
      socket.current?.emit("sendMessage", {
        senderId: userId,
        receiverId,
        text: completePayload.text,
        image: completePayload.image,
      });

      // Post the message to the backend (API call)
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/message`, completePayload)
        .then((res) => {
          // Append the new message to the chat
          setMainMessage((prevMessages) => [...prevMessages, res.data.message]);
          setText(""); // Clear the text input after sending
          setSelectedFile(null); // Clear the file input after sending
          setIsVisibleOpen(false); // Close emoji picker
        })
        .catch((err) => {
          console.error(err);
        });
    };

    // If a file is selected, upload it first
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      // Upload the file
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
        .then((res) => {
          const fileUrl = res.data.url; // Get the file URL after upload
          sendPayload(fileUrl); // Send the file with or without text
        })
        .catch((err) => {
          console.error(err);
          sendPayload(); // If upload fails, send without the file
        });
    } else {
      // If no file is selected, send the message with text only (and possibly emojis)
      sendPayload(); // Send without file
    }
  };

  const handleSendMessage = () => {
    sendMessage();
    setSelectedEmojis([]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file));
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const deleteMessage = async (id) => {
    try {
      await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-message/${id}`
      );
      socket.current.emit("deleteMessage", id);
      getAllMessage(messageChats?._id);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const handleOpenImage = (image) => {
    setImageToOpen(image);
    setOpenImage(true);
  };

  const handleDownloadFile = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl;
    anchor.target = "_blank";
    anchor.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && text.trim() !== "") {
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setText((prevText) => prevText + "\n");
    }
  };
  const defaultMessage =
    "Hi there! 👋 How’s it going? Looking forward to connecting with you.";

  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    const maxCharactersPerLine = 50;
    const lines = inputValue.split("\n");
    const truncatedLines = lines.map((line) => {
      if (line.length > maxCharactersPerLine) {
        const chunks = [];
        while (line.length > 0) {
          chunks.push(line.slice(0, maxCharactersPerLine));
          line = line.slice(maxCharactersPerLine);
        }
        return chunks.join("\n");
      } else {
        return line;
      }
    });
    const newText = truncatedLines.join("\n");

    setText(newText);
  };
  useEffect(() => {
    if (Array.isArray(mainMessage) && mainMessage.length === 0) {
      setText(defaultMessage);
    } else {
      setText("");
    }
  }, [mainMessage, defaultMessage]);
  return (
    <>
      <div className="main-chat-message modalBody">
        {mainMessage?.map((c) => {
          const isOwnMessage = c?.sender === userId;
          const messageUser = user[c?.sender];
          const isImage =
            c?.image &&
            fileText.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const isDocument =
            c?.image &&
            fileImage.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const text = c?.text;

          return (
            <div
              className={isOwnMessage ? "message own" : "message"}
              key={c._id}
            >
           <div
  className={`messageTop ${
    hoveredMessageId === c._id ? "messageTopHovered" : ""
  }`}
  onMouseEnter={() => handleMouseEnter(c._id)}
  onMouseLeave={handleMouseLeave}
>
                {/* Left side (Image or text) */}
                <div className="messageLeft">
                  {/* Display image if it's an image */}
                  {isImage && (
                    <img
                      src={c?.image}
                      alt="attached"
                      className="messageImage"
                      onClick={() => handleOpenImage(c?.image)}
                    />
                  )}
 {isDocument && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDownloadFile(c?.image)}
                >
                  {c?.image.toLowerCase().endsWith(".pdf") ? (
                    <FaRegFilePdf size={30} />
                  ) : c?.image.toLowerCase().endsWith(".xls") ||
                    c?.image.toLowerCase().endsWith(".xlsx") ? (
                    <FaFileExcel size={30} />
                  ) : c?.image.toLowerCase().endsWith(".ppt") ||
                    c?.image.toLowerCase().endsWith(".pptx") ? (
                    <FaFilePowerpoint size={30} />
                  ) : (
                    <IoDocumentTextOutline size={30} />
                  )}
                </div>
              )}
                  {/* Display text if it exists */}
                  {text && text !== "" && (
                    <span className="messageText">{text}</span>
                  )}
                </div>

                {/* Right side (User Avatar) */}
                <div className="messageRight">
                  {/* User Avatar */}
                  <img
                    className="messageUserAvatar"
                    src={
                      messageUser?.avatar ? messageUser?.avatar : companyLogo
                    }
                    alt="avatar"
                    onClick={() =>
                      isOwnMessage
                        ? history.push(`/portal/my-profile`)
                        : history.push(`/portal/otherProfile/${c?.sender}`)
                    }
                  />
                </div>
              </div>

              {/* If hovering over message, show options (delete, reply, etc.) */}
              {isOwnMessage && hoveredMessageId === c._id && (
                <div className="messageActions">
                  <span onClick={() => deleteMessage(c?._id)}>
                    <BiTrash size={20} />
                  </span>
                  <span onClick={() => handleReply(c)}>
                    <FaReply size={20} />
                  </span>
                  <TimeAgo timestamp={c?.timestamps?.createdAt} />
                </div>
              )}

              {/* Handle document type files */}
             
            </div>
          );
        })}
      </div>

      <>
        <div className="selectedInfo">
          <p>{selectedEmojis}</p>
          {selectedFile && (
            <>
              {selectedFile.type.startsWith("image/") && ( // Check if the file is an image
                <img
                  src={filePreview}
                  alt="Selected File"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              )}
              {!selectedFile.type.startsWith("image/") && ( // Display file name if not an image
                <>
                  <InputGroup.Text className="file-name">
                    {selectedFile.name}
                    <div
                      className="att-close"
                      onClick={() => setSelectedFile("")}
                    >
                      x
                    </div>
                  </InputGroup.Text>
                </>
              )}
            </>
          )}
        </div>
        <div className="chatBoxBottom">
          <InputGroup className="footer-header">
            <GoSmiley
              size={20}
              className="chat-smile"
              onClick={() => setIsVisibleOpen(!isVisibleOpen)}
            />

            <textarea
              placeholder={"Please Enter the Message"}
              ref={textareaRef}
              className="footer-header chat-input font"
              aria-label="First name"
              aria-describedby="basic-addon1"
              type="text"
              rows={10}
              value={text}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            ></textarea>

            <InputGroup.Text
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            >
              <IoIosAttach size={20} />
            </InputGroup.Text>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </InputGroup>
          <button className="chatSubmitButton" onClick={handleSendMessage}>
            <LuSendHorizonal size={20} />
          </button>
        </div>
        <Dialog open={isVisibleOpen}>
          <div className={isVisibleOpen ? "emoji-wrap" : "d-none"}>
            <div
              className="emoji-close"
              onClick={() => setIsVisibleOpen(false)}
            >
              X
            </div>
            <Picker
              data={data}
              previewPosition="none"
              onEmojiSelect={handleEmojiSelect}
            />
          </div>
        </Dialog>
      </>
    </>
  );
};

export default MessageChat;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./conver.css";
import companyLogo from "../../images/profile-image.svg";

const Conversation = ({ userId, conversation }) => {
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  
  const friendId = conversation.members.find((m) => m !== userId);
  const conversationId = conversation._id;

  // Function to fetch user details
  const fetchUserDetails = async () => {
    try {
      const res = await axios.get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`);
      setUser(res.data.aboutOne);
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };

  // Function to fetch all messages
  const fetchMessages = async () => {
    try {
      const res = await axios.get(`${process.env.PUBLIC_URL}/xakal/message/${conversationId}`);
      const fetchedMessages = res.data.message;
      
      // Sort messages by timestamp
      fetchedMessages.sort((a, b) => new Date(b.timestamps.createdAt) - new Date(a.timestamps.createdAt));
      
      setMessages(fetchedMessages);
      
      // Calculate new messages count
      const newMessages = fetchedMessages.filter(msg => !msg.read && msg.sender === friendId);
      setNewMessagesCount(newMessages.length);
    } catch (err) {
      console.error("Error fetching messages:", err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, friendId]);

  const lastMessage = messages.length > 0 ? messages[0] : null;

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const messageDate = new Date(timestamp);
    const diffTime = now - messageDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffDays <= 7) {
      return messageDate.toLocaleDateString([], { weekday: "short" });
    } else {
      return messageDate.toLocaleDateString([], { month: "short", day: "numeric" });
    }
  };

  return (
    <div className="conver-div">
      <div className="conver-img-left">
        <img
          src={user?.avatar ? user.avatar : companyLogo}
          className="conver-img"
          alt="user avatar"
        />
      </div>
      <div className="conver-details">
        <div className="mainaccess">
          <span className="conver-name">
            {`${user?.firstName || "Friend"} ${user?.lastName || ""}`}
          </span>
          
        </div>
        {lastMessage && (
          <div className="last-div-count">
            <div className="conver-last-message">
              <p>
                {lastMessage.sender === userId ? "You: " : `${user?.firstName || "Friend"}: `}
                {lastMessage.text.length > 10 ? `${lastMessage.text.substring(0, 10)}...` : lastMessage.text}
              </p>
            </div>
            {newMessagesCount > 0 && (
              <span className="new-messages-count">{newMessagesCount}</span>
            )}
            <p className="message-timestamp">
            {lastMessage ? formatTimestamp(lastMessage.timestamps.createdAt) : ""}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Conversation;

import React from "react";
import { FaFile, FaPhotoVideo } from "react-icons/fa";
import "./textMedia.css";

const EbookMediaForm = ({
  shortDesc,
  longDesc,
  setShortDesc,
  setLongDesc,
  handleToggle,
  bookTitle,
  setBookTitle,
  handleDivBookFileClick,
  fileInputBookFileRef,
  handleEbookFileChange,
  fileInputBookVideoRef,
  handleEbookVideoChange,
  handleCoverChange,
  bookVideo,
  handleDivBookCoverClick,
  fileInputBookCoverRef,
  file,
  setFile,
  bookCover,
  setBookCover,
  removeBookFile,
  handleDivBookPhotoClick,
  fileInputBookPhotoRef,
  handleEbookImageChange,
  bookPhoto,
  removeBookVideo,
  removeBookImage,
  handleDivBookVideoClick,
}) => {
  return (
    <div className="article-wrap">
      <div className="textContent">
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
        />
      </div>
      <div className="character">{bookTitle?.length}/100 Characters</div>
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivBookFileClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaFile size={20} />
          File +
        </div>
        <input
          type="file"
          accept=".pdf,.doc,.docx" // Restrict file types
          multiple
          ref={fileInputBookFileRef}
          style={{ display: "none" }}
          onChange={handleEbookFileChange}
        />
      </div>
      {file.length > 0 && (
        <div>
          <h2>Uploaded Files:</h2>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {file.map((fileName, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "5px 0",
                }}
              >
                <FaFile size={20} style={{ marginRight: "10px" }} />
                <span style={{ flexGrow: 1 }}>{fileName.name}</span>
                <button
                  onClick={() => removeBookFile(index)}
                  style={{
                    background: "rgba(255, 255, 255, 0.7)",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "5px",
                    fontSize: "16px",
                  }}
                >
                  ✖️
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivBookPhotoClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Photo +
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputBookPhotoRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleEbookImageChange}
        />
      </div>
      {bookPhoto.length > 0 && (
        <div>
          <h2>Preview:</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {bookPhoto.map((image, index) => (
              <div key={index} style={{ position: "relative", margin: "5px" }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index}`}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                    borderRadius: "4px",
                  }}
                />
                <button
                  onClick={() => removeBookImage(index)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "rgba(255, 255, 255, 0.7)",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                >
                  ✖️
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivBookVideoClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Video +
        </div>
        <input
          type="file"
          accept="video/*"
          multiple
          ref={fileInputBookVideoRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleEbookVideoChange}
        />
      </div>
      {bookVideo.length > 0 && (
        <div>
          <h2>Preview:</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {bookVideo.map((video, index) => {
              const videoURL = URL.createObjectURL(video);
              return (
                <div
                  key={index}
                  style={{ position: "relative", margin: "5px" }}
                >
                  <video
                    src={videoURL}
                    controls
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    onClick={() => removeBookVideo(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                      padding: "2px",
                    }}
                  >
                    ✖️
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}

      
      <div className="textContent">
      <div className="thumb">Thumbnail</div>
        <div
          className="option"
          onClick={handleDivBookCoverClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Upload
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputBookCoverRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleCoverChange}
        />
      </div>

      {bookCover && (
        <img
          src={URL.createObjectURL(bookCover)} // Create URL for the selected image
          alt="Selected Images"
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            borderRadius: "4px",
          }}
        />
      )}
      <div className="textContent">
        Short Description
        <textarea
          value={shortDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setShortDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{shortDesc?.length}/300 Characters</div>
      </div>
      <div className="textContent">
        Long Description
        <textarea
          value={longDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setLongDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{longDesc?.length}/3000 Characters</div>
      </div>
      <div className="buttonDiv">
        <button className="button2" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default EbookMediaForm;

import React, {useState} from "react";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import axios from "axios";
import "../MyProfile/service.Popup.css";

const FooterInterviewPopup = ({ data, BookSlot }) => {
  // eslint-disable-next-line no-unused-vars
  const [savedApplieds, setSavedApplieds] = useState(false);
  const [savedInterviews, setSavedInterviews] = useState([]);
  const updateToSavedInterview = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/update-saved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  return (
    <div
      className="modalfooter"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        gap:"10px"
      }}
    >
      {savedApplieds ||
                  savedInterviews.includes(data?._id) ? (
                    <div
                      onClick={() =>
                        updateToUnsavedInterview(data?._id)
                      }
                    >
                      <img
                        src={iBookmarkActive}
                        className="bookmark-icon"
                        alt="icon"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={(e) => updateToSavedInterview(data?._id)}
                    >
                      <img
                        src={iBookmark}
                        className="bookmark-icon"
                        alt="icon"
                      />
                    </div>
                  )}
                  <div className="prev-btn-wrap">
          <button
            type="button"
            className="btn job-btn bs-btn"
            onClick={BookSlot}
          >
            Book Slot
          </button>
        </div>
      
    </div>
  );
};

export default FooterInterviewPopup;

/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import Building from "../../images/Buildings.png";
import Associate from "../../images/Associates.svg";
import Follow from "../../images/Associate.svg";

const AssociatesCards = styled.div`
  display: flex;
  flex-wrap:wrap;
  gap:30px;
  justify-content:center;
  margin-left:10px;
`;
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 136px;
  display: flex;
  justify-content:space-between;
  width: 540px;
  gap: 24px;
`;

const Img = styled.img`
  width: 98px;
  height: 98px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyAssociatesList = ({
  userId,
  asscdata,
  followingData,
  actualData,
  findChanges,
}) => {
  const history = useHistory();

  const findAssociateStatus = (id) => {
    console.log("ass id", id);
    console.log("asscdata", asscdata);
    const status = asscdata?.find((e) => e.associateId === id);
    console.log("status?.connectionStatus", status?.connectionStatus);
    if (status) return status?.connectionStatus;
    else return "NOT_FOUND";
  };

  const findFollowingStatus = (id) => {
    const status = followingData?.find((e) => e.followerId === id);
    if (status && status?.status === true) return "EXISTS";
    else return "NOT_FOUND";
  };

  const sentAssociateInvitation = async (associateId) => {
    try{
      const res = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/associate`, {
        appUserId: userId,
        associateId: associateId,
        connectionStatus: "PENDING",
      })
        console.log("added to associate", res);
        findChanges();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const updateInvitation = async (associateId) => {
    try{
      const res = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: associateId,
        associateId: userId,
        connectionStatus: "REJECTED",
      })
        console.log("update Invitation", res?.data);
        findChanges();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const updateFollowStatus = async (status, associateId) => {
    const body = {
      appUserId: userId,
      followerId: associateId,
      status: status,
    };
    try{
      const res = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/follower`, body)
        console.log("res", res?.data);
        findChanges();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  return (
    <div>
      <AssociatesCards>
        {actualData?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <div className="assc-wrap">
              <Img src={invitation?.details?.avatar} alt="" />
            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              {/* <a
                onClick={() =>
                  history.push(
                    "/portal/otherProfile/" +
                      invitation?.details?.userId,
                  )
                }
                style={{cursor:"pointer"}}
              >
                View
              </a> */}
              <div className="assc-desig">Product Manager</div>
              <div className="assc-wraps">
                <img src={Building} alt="img" />
                <div className="assc-desig">Codeup Global</div>
              </div>
            </DetailsWrapper>
            </div>
            <div>
              <div>
              {findAssociateStatus(invitation?.details?.userId) ===
              "PENDING" ? (
                <div className="social-wrapper">
                  <div className="social-btn">Pending</div>
                  <div className="social-btn">
                    <QueryBuilderOutlinedIcon />
                  </div>
                </div>
              ) : null}
              </div>
              <div>
              {findAssociateStatus(invitation?.details?.userId) ===
              "ASSOCIATE" ? (
                <div
                  className="social-wrapper assc-btn"
                  onClick={() => updateInvitation(invitation?.details?.userId)}
                >
                  <div className="social-btn assc-btn">Associate</div>
                  <div className="social-btn">
                    <img src={Associate} alt="img" />
                  </div>
                </div>
              ) : null}

              {findAssociateStatus(invitation?.details?.userId) !==
                "ASSOCIATE" &&
              findAssociateStatus(invitation?.details?.userId) !== "PENDING" ? (
                <div
                  className="social-wrapper fol-wrap"
                  onClick={() =>
                    sentAssociateInvitation(invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Associate</div>
                  <div className="social-btn">
                  <img src={Associate} alt="img" />
                  </div>
                </div>
              ) : null}
              </div>
              <div>
              {findFollowingStatus(invitation?.details?.userId) === "EXISTS" ? (
                <div
                  className="social-wrapper fol-wrap"
                  onClick={() =>
                    updateFollowStatus(false, invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Following</div>
                  <div className="social-btn">
                  <img src={Follow} alt="img" />
                  </div>
                </div>
              ) : (
                <div
                  className="social-wrapper"
                  onClick={() =>
                    updateFollowStatus(true, invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Follow</div>
                  <div className="social-btn">
                  <img src={Follow} alt="img" />
                  </div>
                </div>
              )}
              </div>
            </div>
          </InvitationCard>
        ))}
      </AssociatesCards>
    </div>
  );
};

export default MyAssociatesList;

import React, { createContext, useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import Common from "../../utils/common";

// Create a Context for chat state
const ChatContext = createContext();

// Provider component that will wrap your app
export const ChatProvider = ({ children }) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [conversation, setConversations] = useState([]);
    const [messageSearch, setMessageSearch] = useState(false);
  
  const [count, setCount] = useState(0);
  const socket = useRef();

  const fetchMessagesForConversation = async (conversationId) => {
    try {
      const messageRes = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${conversationId}`
      );

      const sortedMessages = messageRes.data.message.sort(
        (a, b) =>
          new Date(b.timestamps.createdAt) - new Date(a.timestamps.createdAt)
      );

      const unreadCount = sortedMessages.filter(
        (msg) => !msg.read && msg.sender !== Common.loggedIn()
      ).length;

      return { sortedMessages, unreadCount };
    } catch (err) {
      console.error("Error fetching messages for conversation:", err);
      return { sortedMessages: [], unreadCount: 0 };
    }
  };

  const fetchConversations = async () => {
    try {
      const conversationRes = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
      );

      const conversations = conversationRes.data.conver;

      const conversationsWithMessages = await Promise.all(
        conversations.map(async (c) => {
          const { sortedMessages, unreadCount } =
            await fetchMessagesForConversation(c._id);
          return {
            ...c,
            messages: sortedMessages,
            unreadCount,
          };
        })
      );

      const filteredConversations = conversationsWithMessages.filter(
        (convo) => convo.messages.length > 0
      );

      const sortedConversations = filteredConversations.sort((a, b) => {
        const latestMessageA = a.messages[0];
        const latestMessageB = b.messages[0];

        if (!latestMessageA) return 1;
        if (!latestMessageB) return -1;

        return (
          new Date(latestMessageB.timestamps.createdAt) -
          new Date(latestMessageA.timestamps.createdAt)
        );
      });

      setConversations(sortedConversations);

      const totalUnreadCount = sortedConversations.reduce(
        (total, convo) => total + convo.unreadCount,
        0
      );
      setCount(totalUnreadCount);
    } catch (err) {
      console.error("Error fetching conversations:", err);
    }
  };

  useEffect(() => {
    // Initialize socket connection on component mount
    if (!socket.current) {
      socket.current = io(process.env.PUBLIC_URL);
    }

    fetchConversations(); // Fetch initial conversations

    const handleNewConversation = async (newConversation) => {
      if (openMessage) {
        const { sortedMessages, unreadCount } =
          await fetchMessagesForConversation(newConversation._id);

        const updatedConversation = {
          ...newConversation,
          messages: sortedMessages,
          unreadCount,
        };

        setConversations((prevConversations) => {
          const existingConversation = prevConversations.find(
            (conv) => conv._id === updatedConversation._id
          );

          let updatedConversations;
          if (existingConversation) {
            updatedConversations = prevConversations.map((conv) =>
              conv._id === updatedConversation._id ? updatedConversation : conv
            );
          } else {
            updatedConversations = [
              updatedConversation,
              ...prevConversations,
            ];
          }

          updatedConversations.sort((a, b) => {
            const latestMessageA = a.messages[0];
            const latestMessageB = b.messages[0];

            if (!latestMessageA) return 1;
            if (!latestMessageB) return -1;

            return (
              new Date(latestMessageB.timestamps.createdAt) -
              new Date(latestMessageA.timestamps.createdAt)
            );
          });

          return updatedConversations;
        });

        setCount((prevCount) => prevCount + unreadCount);
      }
    };

    socket.current.on("newConversation", handleNewConversation);

    return () => {
      socket.current.off("newConversation", handleNewConversation);
    };
  }, [openMessage]);

  return (
    <ChatContext.Provider
      value={{
        openMessage,
        setOpenMessage,
        conversation,
        fetchConversations,
        count,
        setCount,
        messageSearch,setMessageSearch
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook to use the chat state
export const useChat = () => {
  return useContext(ChatContext);
};

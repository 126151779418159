import React from "react";
import { FaPhotoVideo } from "react-icons/fa";

const CourseMediaForm = ({
  courseTitle,
  setCourseTitle,
  handleDivThumbnailSnippet,
  fileInputrefThumbnail,
  handleThumbnailChange,
  thumbnailCover,
  setShortDesc,
  setLongDesc,
  shortDesc,
  longDesc,
  handleToggle,
  triggerFileInput,
  chapters,
  handleAddChapter,
  handleChapterChange,
  handleRemoveChapter,
  handleAddSection,
  handleRemoveSection,
  handleAddContentItem,
  handleContentItemChange,
  handleRemoveContentItem,
  fileInputRefs,
  handleFileUpload,
  handleSectionChange,
}) => {
  return (
    <div className="article-wrap">
      {/* Course Title */}
      <div className="textContent">
        Course Title
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={courseTitle}
          onChange={(e) => setCourseTitle(e.target.value)}
        />
      </div>
      <div className="character">{courseTitle?.length}/100 Characters</div>

      {/* Add Chapter Button */}
      <div>
        <button className="button2" onClick={handleAddChapter}>
          Add Chapter
        </button>
      </div>

      {/* Chapters and Sections */}
      {chapters.map((chapter, chapterIndex) => (
        <div key={chapterIndex} className="chapter-section">
          <div className="textContent">
            Chapter {chapterIndex + 1} Title
            <input
              type="text"
              placeholder="Chapter Title"
              className="inputField"
              value={chapter.title}
              onChange={(e) =>
                handleChapterChange(chapterIndex, "title", e.target.value)
              }
            />
          </div>
          <button
            className="button2 remove-button"
            onClick={() => handleRemoveChapter(chapterIndex)}
            style={{
              marginTop: "10px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Remove Chapter
          </button>
          <div>
            <button
              className="button2"
              onClick={() => handleAddSection(chapterIndex)}
              style={{ marginTop: "10px" }}
            >
              Add Section
            </button>
          </div>

          {/* Sections and Content Items */}
          {chapter.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-section">
              <div className="textContent">
                Section {sectionIndex + 1} Title
                <input
                  type="text"
                  placeholder="Section Title"
                  className="inputField"
                  value={section.title}
                  onChange={(e) =>
                    handleSectionChange(
                      chapterIndex,
                      sectionIndex,
                      e.target.value
                    )
                  }
                />
              </div>
              {/* Remove Section Button */}
              <button
                className="button2 remove-button"
                onClick={() => handleRemoveSection(chapterIndex, sectionIndex)}
                style={{
                  marginTop: "10px",
                  backgroundColor: "orange",
                  color: "white",
                }}
              >
                Remove Section
              </button>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "14px",
                  margin: "13px",
                }}
              >
                {["video", "photo", "ebook", "text", "article", "quiz"].map(
                  (type) => (
                    <button
                      key={type}
                      className="button2"
                      onClick={() =>
                        handleAddContentItem(chapterIndex, sectionIndex, type)
                      }
                    >
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                  )
                )}
              </div>

              {/* Content Items (video, photo, ebook) */}
              {section.contentItems.map((content, contentIndex) => (
                <div key={contentIndex} className="content-item">
                  <label>{content.type.toUpperCase()}</label>
                  {["text", "article", "quiz"].includes(content.type) ? (
                    <>
                      <input
                        type="text"
                        placeholder={`Enter ${content.type}`}
                        value={content.value}
                        onChange={(e) =>
                          handleContentItemChange(
                            chapterIndex,
                            sectionIndex,
                            contentIndex,
                            e.target.value
                          )
                        }
                        className="inputField"
                      />
                      <button
                        className="remove-button"
                        onClick={() =>
                          handleRemoveContentItem(
                            chapterIndex,
                            sectionIndex,
                            contentIndex
                          )
                        }
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          marginLeft: "10px",
                        }}
                      >
                        Remove
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => triggerFileInput(content.type)}
                        className="button2"
                      >
                        Upload{" "}
                        {content.type.charAt(0).toUpperCase() +
                          content.type.slice(1)}
                      </button>
                      <input
                        type="file"
                        accept={
                          content.type === "photo"
                            ? "image/*"
                            : content.type === "video"
                              ? "video/*"
                              : ".pdf,.doc,.docx"
                        }
                        ref={fileInputRefs.current[content.type]}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileUpload(
                            chapterIndex,
                            sectionIndex,
                            contentIndex,
                            e
                          )
                        }
                      />
                      {content.value && (
                        <div>
                          <p>Uploaded file: {content.value}</p>
                          <button
                            className="remove-button"
                            onClick={() =>
                              handleRemoveContentItem(
                                chapterIndex,
                                sectionIndex,
                                contentIndex
                              )
                            }
                            style={{
                              backgroundColor: "orange",
                              color: "white",
                              marginLeft: "10px",
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      {/* Short Description */}
      <div className="textContent">
        Short Description
        <textarea
          value={shortDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setShortDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{shortDesc?.length}/300 Characters</div>
      </div>

      {/* Long Description */}
      <div className="textContent">
        Long Description
        <textarea
          value={longDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setLongDesc(e.target.value)}
          rows={5}
        />
        <div className="character">{longDesc?.length}/600 Characters</div>
      </div>

      {/* Thumbnail */}
      <div className="image-field">
        <div className="cover-image">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputrefThumbnail}
            onChange={handleThumbnailChange}
          />
          <div className="image-wrap">
            {thumbnailCover ? (
              <img
                src={URL.createObjectURL(thumbnailCover)}
                alt="thumbnail"
                className="img-thumbnail"
                onClick={handleDivThumbnailSnippet}
              />
            ) : (
              <div
                className="image-placeholder"
                onClick={handleDivThumbnailSnippet}
              >
                <FaPhotoVideo size={30} />
                <span className="text">Upload Thumbnail</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buttonDiv">
        <button className="button2" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CourseMediaForm;
